/**
 * Equipment creation and correction logic
 *
 * @2023/05/30
 */

import { useState } from 'react';

import { findItemByType } from '../store';
import {
  // CATALOGUE_TYPE,
  FittingEntity,
  CATALOGUE_CATEGORY,
  TKEvent,
} from '../types';

export const useEquipmentModal = (onLazyClose: (type?: string) => void) => {
  const [eqmtName, setEqmtName] = useState('');
  const [eqmtSubtype, setEqmtSubtype] = useState('');
  const [sNumber, setSNumber] = useState('');
  const [identifier, setIdentifier] = useState('');

  /**
   * New equipment tag payload
   * @returns
   */
  const createFeatureEntity = (): FittingEntity => {
    const equipmentItem = findItemByType(CATALOGUE_CATEGORY.E);
    const icon = equipmentItem?.icon as string;
    const detail: FittingEntity = {
      // ! here need `EQUIPMENT` like the structure in `jobItemToEquipmentSymbol`
      // @2025/02/04
      category: CATALOGUE_CATEGORY.E, // 2nd level
      system: '',
      entityType: CATALOGUE_CATEGORY.E,
      itemType: CATALOGUE_CATEGORY.E, // 1st level
      icon,
      name: eqmtName,
      subtype: eqmtSubtype,
      s_number: sNumber,
      identifier, // @2024/12/10
    };

    return detail;
  };

  const applyNewEquipmentHandler = () => {
    if (!eqmtName) return; // only equipment name is required

    // notify global filter to show equipment only! - @2025/03/25
    onLazyClose(CATALOGUE_CATEGORY.E);

    const detail = createFeatureEntity();
    // lazy notification to map to add new element!
    setTimeout(() => {
      const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
      document.dispatchEvent(event);
    }, 200);
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    onLazyClose();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  return {
    eqmtName,
    setEqmtName,
    eqmtSubtype,
    setEqmtSubtype,
    sNumber,
    setSNumber,
    identifier,
    setIdentifier,
    applyNewEquipmentHandler,
    cancelNewElementHandler,
  };
};
