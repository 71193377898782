import clsx from 'clsx';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { NEW_DUCT_MODAL_TITLE, SELECT_DUCT_SYSTEM } from '@/config';

import { useDuctModal } from '../hooks/useDuctModal';
import { useNormalView } from '../hooks/useNormalView';
import { DuctDynaFields, CreateMoreSystems } from '../inputs';
import { getDuctFittings, findItemPropertyBy } from '../store';
import {
  FittingEntity,
  DUCTSHAPEOPTS as DTSP,
  TKSystem,
  CATALOGUE_CATEGORY,
  BPPage,
} from '../types';

type DuctModalProps = ModalOpenProps & {
  selectType: string;
  systems: TKSystem[];
  newTypeApplyHandler?: (fe: FittingEntity) => void;
  unit?: string;
  page?: BPPage;
};

/** Draw New Duct Fitting Modal */
export const AddDuctFitting = ({
  isOpen,
  isOnStage,
  selectType,
  systems,
  unit,
  lazyCloseHandler,
}: DuctModalProps) => {
  const {
    currentSystem,
    currentType,
    ductFields,
    shapeType,
    useShape,
    fieldProps,
    setCurrentSystem,
    ductTypeChangeHandler,
    inputFieldRenderer,
    shapeChangeHandler,
    applyNewElementHandler,
    cancelNewElementHandler,
  } = useDuctModal(selectType, lazyCloseHandler);

  const { isNormalView, setIsNormalView, normalView } = useNormalView();
  const hasSystemDefined = findItemPropertyBy(currentType, 'system');

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={isNormalView ? NEW_DUCT_MODAL_TITLE : SELECT_DUCT_SYSTEM}
    >
      {/* == system select == */}
      {hasSystemDefined && (
        <CreateMoreSystems
          systems={systems}
          category={CATALOGUE_CATEGORY.D}
          itemType={selectType}
          currentSystem={currentSystem}
          currentSystemChangeHandler={setCurrentSystem}
          onMoreSystemSwitch={setIsNormalView}
        />
      )}
      {/* == select duct type == */}
      <label className={normalView('color-primary text-sm block mb-4 mt-2')}>
        DUCT FITTING TYPE
        <select
          name="fittingType"
          className="block w-full px-2 py-1 mt-1 text-sm frame-input"
          value={currentType}
          onChange={ductTypeChangeHandler}
        >
          {getDuctFittings(true).map((duct) => (
            <option key={duct.type} value={duct.type}>
              {duct.label}
            </option>
          ))}
        </select>
      </label>
      {/* use shape radio group if necessary */}
      <div className={normalView('flex my-0')}>
        {useShape &&
          DTSP.map((opt) => (
            <label key={opt.value} className="label-block flex-1 color-primary">
              {opt.label}
              <input
                type="radio"
                name="shape"
                value={opt.value}
                checked={shapeType === opt.value}
                onChange={shapeChangeHandler}
              />
              <span className="checkmark"></span>
            </label>
          ))}
      </div>
      {/* Dimension fields: diameter - length - height - width *** */}
      <div className={normalView('flex flex-wrap my-0 gap-0')}>
        <DuctDynaFields
          defaultValues={fieldProps}
          fields={ductFields}
          fieldRenderer={inputFieldRenderer}
          unit={unit}
          itemType={currentType}
        />
      </div>
      {/* button row */}
      <div className={normalView('flex gap-x-10 justify-between mx-0 mt-6')}>
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={cancelNewElementHandler}
        >
          Cancel
        </button>
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base bg-green shadow-md',
          )}
          onClick={applyNewElementHandler}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
