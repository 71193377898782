import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TOKEN_UNAVAILABLE_FORWARD } from '@/config';
import { TOKEN_ERROR_EVENTS } from '@/lib/http';
// import { log, warn } from '@/utils/logger';
import { getThrottleFunction } from '@/utils';

/**
 * Reduce the toast calling to prevent repetitive message popup in case of token conflicts from two apps access.
 * @date 2025/03/10
 */
const throttledErrorToast = getThrottleFunction((...args) => {
  const [message] = args;
  toast.error(message as string);
});

/**
 * Catch Token error to forward to login page,
 * in safe-wrapper for `/home` & `/project` path
 * @log fix redirect method from `windows.location.href` to `navigate` - 2024/11/06
 */
export const useTokenErrorHandler = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const tokenErrorHandler = (event: Event) => {
      // FIXME: General token error handler, forward to login page,
      // except for in login page already!
      // @2024/03/05
      // NOTE: this check seems no longer usefull, cos `pathname` wont change!
      // @2024/11/06
      if (pathname.includes('login')) return;

      // save this error for debug use!
      const defaultMsg = `## GOT TOKEN ERROR, forwarding to login page...`;
      const { detail } = event as CustomEvent;
      const message = detail.description || defaultMsg;
      // ! notify error message to user at this moment
      // @2025/01/07, 03/10
      throttledErrorToast(message);

      setTimeout(() => {
        // FIXME: Safely to redirect to login while got token error(401)!
        // @2024/11/06
        navigate(TOKEN_UNAVAILABLE_FORWARD);
      }, 3000); // wait for message fully display
    };

    TOKEN_ERROR_EVENTS.forEach((event) => {
      document.addEventListener(event, tokenErrorHandler);
    });

    return () => {
      TOKEN_ERROR_EVENTS.forEach((event) => {
        document.removeEventListener(event, tokenErrorHandler);
      });
    };
  }, [pathname, navigate]);
};
