/**
 * Item catalogues implementation with react external store
 * NOTE: experimental feature for future use!
 *
 * @date 2024/08/15
 */
import * as _ from 'lodash-es';
import { useEffect, useSyncExternalStore } from 'react';

import helpIcon from '@/assets/icons/ic_outline-help.svg';
import { moveElementToBack } from '@/utils';
import { log, error } from '@/utils/logger';

import { fetchItemCatalogue } from '../api';
import {
  GO,
  Documents,
  FittingMeta,
  TKJobItem,
  TKItemCatalog,
  CATALOGUE_TYPE as CTLG,
  CATALOGUE_CATEGORY as CTCT,
  LENGTH_TYPE as LEN,
  SPECIAL_JOB_ITEM as SJI,
  itemCatalogueToFittingMeta,
  checkDuctRoundOnly,
  HELP_ICON,
  WIDTH_ICON,
  missingIcons,
  LENGTH_FIELD,
  LENGTH_STR_FIELD,
  DETECTION_MODE,
  SYMBOL_REVISION,
  filterCompletionFields,
  getReducedFieldsByShape,
} from '../types';

/**
 * manualy excluded!
 * @2025/01/16
 * @deprecated in favor of `ITEM_TYPE_HIDDEN`
 */
const ITEM_TYPE_IGNORED = ['DIRECTION_OF_FLOW', 'SHUT_OFF_VALVE'];

/**
 * invisible items from menu list, that means no need to filter out, to add new!
 * @2025/03/13
 */
const ITEM_TYPE_HIDDEN = [
  'DIRECTION_OF_FLOW', // @2025/01/16
  'SHUT_OFF_VALVE', // @2025/01/16
  'ENDPOINT_TYPE_C', // @2025/03/13
  'ENDPOINT_TYPE_D', // @2025/03/13
  'PIPE_ELBOW_TYPE_1', // @2025/03/13
];

/**
 * Check if the item type to show or not
 * @param item item type to show or not
 * @returns true to show, false to hide
 */
const hiddenItemsFilter = (item: string) => !ITEM_TYPE_HIDDEN.includes(item);

// and more catalogues...

type CatalougeState = {
  cataloguesAvailable: boolean;
  /** all the catalogues */
  all: FittingMeta[];
  fittings: FittingMeta[];
  lengths: FittingMeta[];
  plumings: FittingMeta[];
};

// ============ PRIVATE SECTION FOR CATALOGUE STORE ===============

/**
 * snapshot of catalouges state
 */
let initialCatalogueState: CatalougeState = {
  cataloguesAvailable: false,
  all: [],
  fittings: [],
  lengths: [],
  plumings: [],
};

/**
 * callback functions would dynamically change
 */
let listeners: (() => void)[] = [];

const emitChange = () => {
  for (const listener of listeners) {
    listener();
  }
};

// ============ PRIVATE SECTION FOR CATALOGUE STORE ===============

/**
 * TODO: Consider move this function to `catalogueStore`
 * Compose job item state including `vers`, `completed`, `confirmed`
 * DO NOT LOOK `confirmed` as `completed`!
 * @date 2024/04/15
 *
 * @log vers equals to `D` is also considered completed - @2024/05/29
 *
 * @log check `completed` from detail if its not `confirmed` - @2024/12/09
 *
 * @log strict `completed` only determind by version - @2025/02/20
 *
 * @param item
 * @param detail
 * @returns
 */
export const checkJobItemState = (item: TKJobItem, detail: GO) => {
  const { detection_mode } = item;
  const confirmed = !!item.user_validated;
  const vers = checkJobItemVersionBy(
    item.item.id,
    detail,
    confirmed,
    detection_mode,
  );
  const isCompleted = vers === SYMBOL_REVISION.C;
  // const isConfirmed = vers === SYMBOL_REVISION.D;
  // const isManualSymbol = vers === SYMBOL_REVISION.M;
  // const completed = isCompleted || isConfirmed || isManualSymbol;
  // ! FIXME: only check full dimension values to treat it `completed`
  // @2025/02/20
  return { vers, completed: isCompleted, confirmed };
};

/**
 * TODO: Consider move this function to `catalogueStore`
 * General method to check symbol version:
 * Check symbol version if all dimension completed on first round features rendering
 *
 * USE CASE:
 * 1. jobItemTransformer at the jot items loading and transformation
 *
 * Add `shape` checking for version inference - @2023/11/03
 *
 * FIXME: check operation mode to decide version
 * @date 2024/03/21
 *
 * @param symbolType
 * @param detail symbol detail
 * @param userValidated optional user confirmed
 * @param mode optional mode
 * @returns version number
 */
const checkJobItemVersionBy = (
  itemType: string,
  detail: GO,
  userValidated?: boolean,
  mode?: string,
) => {
  if (mode === DETECTION_MODE.M) return SYMBOL_REVISION.M;
  // check manual confirmation first!
  if (userValidated) return SYMBOL_REVISION.D;

  const definedFields = findFieldsByType(itemType);
  const commonFields = ['system', ...definedFields];
  // consider `shape` for dimension
  const completionFields = filterCompletionFields(commonFields);
  const reducedFields = getReducedFieldsByShape(
    completionFields,
    detail['shape'],
  );

  const checkExistence = reducedFields.map(
    (f) => !!detail[f] && detail[f] !== '0',
  );
  const haveEmptyValue = checkExistence.includes(false);

  return haveEmptyValue ? SYMBOL_REVISION.B : SYMBOL_REVISION.C;
};

/**
 * Find item icon from global items store
 * @2024/11/26
 * @param itemType symbol item type, such as `ELBOW`
 * @returns icon file path
 */
export const iconFinderXternal = (itemType: string | undefined) => {
  if (!itemType) return HELP_ICON;
  const { all, lengths } = initialCatalogueState;
  const capitalize = itemType.toUpperCase();
  const length = lengths.find((it) => it.type === capitalize);
  if (length) {
    return WIDTH_ICON;
  }
  const commonItem = all.find((item) => item.type === capitalize);
  if (!commonItem?.icon) {
    // console.warn(`>>> could not find icon for: ${capitalize}`);
    return missingIcons[capitalize] || HELP_ICON;
  }
  return commonItem.icon;
};

/**
 * Find icon defined in item catalogue, if not found, use help icon instead
 * @param itemType
 * @returns
 */
export const iconFinder = iconFinderXternal;

/**
 * get item definition with type
 * @param type item id with capitalized format
 * @returns
 */
export const findItemByType = (type: string) => {
  const catalogues = initialCatalogueState;
  return catalogues.all.find((item) => item.type === type);
};

/**
 * General function to get item properties defined in backend, `system` field not included!
 *
 * @param type item id
 * @returns props fields list
 */
export const findPropsByType = (type: string) => {
  const catalogues = initialCatalogueState;
  const item = catalogues.all.find((item) => item.type === type);
  return item ? item.props : [];
};

/**
 *
 * NO `shape` & NO `system` in result!!
 *
 * @param type item type
 * @returns
 */
export const findFieldsByType = (type: string) => {
  const catalogues = initialCatalogueState;
  const item = catalogues.all.find((item) => item.type === type);
  return item ? item.fields : [];
};

/**
 * Get field definition of one item type
 *
 * @param type item type
 * @param field field name
 * @returns
 */
export const findItemPropertyBy = (type: string, field: string) => {
  const catalogues = initialCatalogueState;
  const item = catalogues.all.find((item) => item.type === type);
  if (!item) return null;
  const property = item.properties.find((p) => p.name === field);
  if (!property) return null;
  return property;
};

export const findRawProperties = (type: string) => {
  const catalogues = initialCatalogueState;
  const item = catalogues.all.find((item) => item.type === type);
  return item ? item.properties : [];
};

export const getFittingFields = (
  type: string,
  checkSystem?: boolean,
  checkShape?: boolean,
) => {
  const catalogues = initialCatalogueState;
  const fitting = catalogues.fittings.find((item) => item.type === type);
  const fields = fitting ? fitting.fields : [];
  const hasShape = fitting ? fitting.shape : false;
  if (!checkSystem) {
    if (checkShape && hasShape) {
      return ['shape', ...fields];
    }
    return fields;
  }
  const withSystem = fitting ? !!fitting.hasSysem : false;
  return withSystem ? [...fields, 'system'] : fields;
};

export const getLengthFields = (type: string, checkShape?: boolean) => {
  const catalogues = initialCatalogueState;
  const lengths = catalogues.lengths.find((item) => item.type === type);
  if (!lengths) return [];
  const fields = lengths.fields;
  return checkShape ? ['shape', ...fields] : fields;
};

/**
 * get shape existence
 *
 * FIXME: check if round only, then does not need shape select in new duct dialog
 * @date 2024/03/21
 *
 * @param ductType duct type with snake case format
 * @returns
 */
export const shapeFinder = (ductType: string | undefined) => {
  if (!ductType) return false;
  const capitalize = ductType.toUpperCase();
  // thats rectangular, so need shape
  if (capitalize === SJI.RECT_DUCT) return true;

  // NO need to toggle shape select!
  const isRoundOnly = checkDuctRoundOnly(ductType);
  if (isRoundOnly) return false;
  // checking item catalogue store:
  const fitting = getDuctFittings().find((duct) => duct.type === capitalize);
  return fitting ? !!fitting.shape : false;
};

/**
 * need to control duct visibility...
 * @param hideInVisible
 * @returns
 */
export const getDuctFittings = (hideInVisible = false) => {
  const catalogues = initialCatalogueState;
  if (!catalogues.fittings.length) return [];
  const fittings = catalogues.fittings.filter(
    (item) => item.category === CTCT.D,
  );
  if (hideInVisible) {
    return fittings.filter((item) => hiddenItemsFilter(item.type));
  }
  return fittings;
};

/**
 * need to control pipe visibility...
 * @param hideInVisible
 * @returns
 */
export const getPipeFittings = (hideInVisible = false) => {
  const catalogues = initialCatalogueState;
  if (!catalogues.fittings.length) return [];
  const pfilter = (item: FittingMeta) => item.category === CTCT.P;
  const pfits = catalogues.fittings.filter(pfilter);
  if (hideInVisible) {
    return pfits.filter((item) => hiddenItemsFilter(item.type));
  }
  return pfits;
};

/**
 * need to control plumings visibility...
 * @param hideInVisible
 * @returns
 */
export const getPlumbings = (hideInVisible = false) => {
  const plumings = initialCatalogueState.plumings;
  if (hideInVisible) {
    return plumings.filter((item) => hiddenItemsFilter(item.type));
  }
  return plumings;
};

/**
 * Unified && flexible fields fetcher, for length symbol to add `length` field
 *
 * FIXME: is it right place to add length field here?
 * FIXME: to review all the parameters...if necessary - 2023/11/17
 *
 * USE CASES:
 * - FeatureProperties display
 *
 * @param type item type
 * @param checkShape
 * @returns
 */
export const getSymbolFields = (type: string, checkShape?: boolean) => {
  if (type === LEN.P || type === LEN.D) {
    const fields = getLengthFields(type, checkShape);
    const length_str_position = fields.indexOf(LENGTH_STR_FIELD);
    if (length_str_position > -1) {
      return moveElementToBack(fields, length_str_position);
    }
    return fields;
  }
  // FIXME: simpler way to find fields - @2023/08/25
  return findFieldsByType(type);
};

/**
 * TODO: move to `useCatalogueStore`
 * Find out common fields of different fittings
 *
 * TWO cases for this function:
 *
 * - `useExpendComnFields` hook doese not need str length field.
 * - `getCommonFields4Table` to figure out the columns of table, it does need str length field.
 *
 * So, add one more optional parameter `ignoreLength`, true by default, but false in the 2nd case.
 *
 * @date 2024/03/14
 *
 * @param types entity types
 * @param ignoreLength if need length to display
 */
export const getCommonFieldsOfFittings = (
  types: string[],
  ignoreLength = true,
) => {
  // Validate step : null, may need other filter...
  const validTypes = types.filter((type) => !!type);

  const allFieldsByType: string[][] = [];
  const allFieldsInSet = new Set<string>();
  const checkExistanceInAll = (field: string) => {
    let existing = true;
    allFieldsByType.forEach((fields) => {
      if (!fields.includes(field)) return (existing = false);
    });
    return existing;
  };
  // step 1: get all the fields of all the types
  validTypes.forEach((type) => {
    // TODO: use the function in store:
    const fields = findPropsByType(type);
    // console.log(fields);
    fields.forEach((f) => allFieldsInSet.add(f));
    allFieldsByType.push(fields);
  });
  // step 2: find the common fields across all the types
  const commonFields: string[] = [];
  allFieldsInSet.forEach((f) => {
    const isCommon = checkExistanceInAll(f);
    isCommon && commonFields.push(f);
  });

  // FIXME: remove `length_1` & `length_unit_str` field if its in common fields
  // @2024/02/21
  const lengthFieldPos = commonFields.indexOf(LENGTH_FIELD);
  if (lengthFieldPos != -1) {
    commonFields.splice(lengthFieldPos, 1);
  }
  const lengthStrFieldPos = commonFields.indexOf(LENGTH_STR_FIELD);
  if (lengthStrFieldPos != -1 && ignoreLength) {
    commonFields.splice(lengthStrFieldPos, 1);
  }

  // FIXME: add `system` as default common field
  // @2023/08/30
  // if (!commonFields.includes('system')) {
  //   return commonFields.concat('system');
  // }
  return commonFields;
};

/**
 * catalogue operations interface exposed to whole app
 */
export const itemCatalogueStore = {
  /**
   * ============== WRITE CATALOGUES METHOD =============
   * @param catalogues write catalouge to store
   */
  addItemCataluges(catalogues: TKItemCatalog[]) {
    const { cataloguesAvailable } = initialCatalogueState;
    // prevent unnecessary reinit state!
    if (cataloguesAvailable) return;
    // clean up first
    const all = catalogues.map((catalogue) =>
      itemCatalogueToFittingMeta(catalogue),
    );
    // console.log(`## got catalogues in total: ${all.length}`);
    const fittings = all.filter((item) => item.rawType === CTLG.F);
    const lengths = all.filter((item) => item.rawType === CTLG.L);
    const plumings = all.filter((item) => item.category === CTCT.PLM);
    // == build a new state: ===
    initialCatalogueState = {
      cataloguesAvailable: true,
      all,
      fittings,
      lengths,
      plumings,
    };
    // == trigger react update ==
    emitChange();
  },
  // ======== separation line ========
  // == inline method ==
  subscribe(listener: () => void) {
    listeners = [...listeners, listener];
    return () => {
      // remove the newly added listener to achieve unsubscribe
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  /**
   * exposed state change handler for `useSyncExternalStore`
   * @returns an immutable catalogues store state
   */
  getSnapshot() {
    return initialCatalogueState;
  },
};
/**
 * Global symbol item definition store
 * @returns cataglogue items
 */
export const useCatalogueStore = (companyId?: number) => {
  const { subscribe, getSnapshot } = itemCatalogueStore;
  const catalogues = useSyncExternalStore(subscribe, getSnapshot);

  // === Common method to find fields of a specific item type ===

  const findIconByType = (type: string) => {
    const item = catalogues.all.find((item) => item.type === type);
    return item ? item.icon : helpIcon;
  };

  /**
   * pipe fitting types in snake case
   * @deprecated no where to use
   * @returns set
   */
  const getPipeTypeSet = () => {
    const types = new Set<string>();
    getPipeFittings().forEach((p) => types.add(_.snakeCase(p.type)));
    return types;
  };

  /**
   * duct fitting types in snake case
   * @deprecated
   * @returns set
   */
  const getDuctTypeSet = () => {
    const types = new Set<string>();
    getDuctFittings().forEach((d) => types.add(_.snakeCase(d.type)));
    return types;
  };

  useEffect(() => {
    if (initialCatalogueState.cataloguesAvailable) {
      // log(`## catalogues already loaded!`);
      return;
    }
    const loadItems = async () => {
      try {
        const response = await fetchItemCatalogue(companyId);
        const { documents } = response.data as Documents;
        const catalogues = documents as TKItemCatalog[];
        // console.log(documents);
        // 1. cache first
        itemCatalogueStore.addItemCataluges(catalogues);
        // 2. rerender the UI dropdown list
        log('## got the item catalogues defintion!');
      } catch (errInfo: any) {
        error(`### loading item catalouges having an error or timeout!`);
        log(errInfo);
        // toast.warn(ITEM_CATALOGUES_LOAD_FAILED);
      }
    };
    loadItems();
  }, [companyId]);

  return {
    ...catalogues,
    getPipeFittings,
    findIconByType,
  };
};
