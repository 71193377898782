/**
 * build duct fields dynamically
 * @2023/02/16
 */

import * as _ from 'lodash-es';

import { findItemPropertyBy } from '../store';
import {
  GO,
  isDimensionField,
  getInputSuffixBy,
  FIELD_W2,
  FIELD_W1,
  coarseUnitSuffix,
} from '../types';

import { FittingFieldElement as FFE, InputRendererProps } from './form-inputs';

type DuctFieldsProps = {
  /**
   * What do these fields belong to?
   * Could be `undefined` for common item types
   */
  itemType: string | undefined;
  /** page unit */
  unit?: string;
  fields: string[];
  defaultValues?: GO;
  fieldRenderer: (fieldName: string) => InputRendererProps;
};

const checkValueDefined = (v: any) => {
  if (typeof v == 'undefined') return false;
  if (v === null) return false;
  if (v === 'null') return false;
  if (v === 'undefined') return false;
  return true;
};

const findInputMinMaxBy = (
  itemType: string | undefined,
  field: string,
  unit: string | undefined,
) => {
  const rawItemProps = itemType ? findItemPropertyBy(itemType, field) : null;
  // end props for input:
  let plcMinMax = {};
  if (rawItemProps) {
    const lowerBound = (rawItemProps as GO)[`${unit}_lower_bound`];
    const upperBound = (rawItemProps as GO)[`${unit}_upper_bound`];
    const lowerBoundDefined = checkValueDefined(lowerBound);
    const upperBoundDefined = checkValueDefined(upperBound);
    plcMinMax = {
      placeholder:
        lowerBoundDefined && upperBoundDefined
          ? `min: ${lowerBound}, max: ${upperBound}`
          : '',
      min: lowerBound || undefined, // do not use null!
      max: upperBound || undefined, // do not use null!
    };
  }
  return plcMinMax;
};

/**
 * General Input field in:
 * - batch correction side panel
 * - add new symbol modal
 */
export const DuctDynaFields = ({
  itemType,
  fields,
  fieldRenderer,
  unit,
  defaultValues,
}: DuctFieldsProps) => (
  <>
    {fields.map((f, i, _fields) => {
      const snakeField = _.snakeCase(f);
      const InputByField = FFE[snakeField] || FFE['default'];
      const hasTwoWidth = _fields.includes(FIELD_W2);
      const hasWidthAndWidth1 =
        _fields.includes(FIELD_W1) && _fields.includes('width');
      const isNumberInput = isDimensionField(snakeField);
      // check reset value, cos `defaultValues` could be undefined
      const resetFieldValue = { fieldValue: defaultValues?.[f] || '' };
      // @2025/02/28
      const needCoarseUnit = coarseUnitSuffix.includes(f);
      const suffix = getInputSuffixBy(unit, needCoarseUnit);
      // find lower-bound & upper-bound for current item type!
      // @2025/03/07
      const plcMinMax = findInputMinMaxBy(itemType, f, unit);
      return (
        <InputByField
          key={f}
          oneWidthOnly={!hasTwoWidth && !hasWidthAndWidth1}
          suffix={isNumberInput ? suffix : ''}
          type={isNumberInput ? 'number' : 'text'}
          {...fieldRenderer(f)}
          {...resetFieldValue}
          {...plcMinMax}
        />
      );
    })}
  </>
);
