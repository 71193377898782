import { BPMapEditorAPI } from '../map/BPMapAPI';

/**
 * Show context menu on map, write feature info to it for menu item click use
 *
 * @param x cxt menu position x
 * @param y cxt menu position y
 * @param featureId feature id, the sae as symbol id
 * @param itemType item type(FITTING|LENGTH) to write on cxt menu
 * @param type item class, suc as `ELBOW`
 * @param category item category of the feature selected
 * @param index item index
 */
const popupContextMenu = (
  x: number,
  y: number,
  featureId?: string,
  itemType?: string,
  type?: string,
  category?: string,
  index?: number,
) => {
  const mCtxMenu = document.querySelector('.map-context-menu') as HTMLElement;
  mCtxMenu.classList.remove('hidden'); // show the list
  mCtxMenu.setAttribute('style', `top:${y}px;left:${x + 4}px;`); // set the right position
  // save the feature under the mouse position
  mCtxMenu.dataset.feature = featureId || '';
  mCtxMenu.dataset.itemid = featureId || '';
  mCtxMenu.dataset.itemtype = itemType || '';
  mCtxMenu.dataset.category = category || '';
  mCtxMenu.dataset.index = `${index}`;
  mCtxMenu.dataset.type = type || '';
};

/**
 * display context menu list ...
 * @param map
 * @param locked
 * @returns
 */
export const domContextMenuHandler =
  (map: BPMapEditorAPI, locked: boolean) => (event: MouseEvent) => {
    if (locked) return event.preventDefault();

    // FIXME: do not popup this while Ctrl key pressed!
    // cos click endpoint of a line while holding Ctrl key will trigger this!!!
    // @2024/07/05
    if (map.isCtrlKeyOnHold()) {
      event.preventDefault();
      return;
    }

    // FIXME: only happens at product version
    if (process.env.NODE_ENV === 'production') {
      event.preventDefault(); // disable browser builtin context menu!
    }

    // FIXME: if drawing locked, no contextmenu popup
    const isMapLocked = map.getMapEditLock();
    if (isMapLocked) return console.warn('## Mapd locked to changes!');

    // CASE 1: === only popup above canvas ===
    const isMapCanvas = event.target instanceof HTMLCanvasElement;
    if (isMapCanvas) {
      const { pixel, selectedFeature, itemType, type, category, index } =
        map.onContextMenuPressed(event);
      if (!selectedFeature) {
        // FIXME: no feature touched, no popup allowed @2022/12/09
        return console.warn('No feature under mouse!');
      }
      return popupContextMenu(
        pixel[0],
        pixel[1],
        selectedFeature,
        itemType,
        type,
        category,
        index,
      );
    }
  };
