/**
 * Add Pipe fitting modal
 *
 * @date 2023/12/20
 */
import * as _ from 'lodash-es';
import { useState, useEffect } from 'react';

import { kebabCase } from '@/utils';

import { InputRendererProps } from '../inputs';
import { iconFinder, findFieldsByType } from '../store';
import {
  GenericObject,
  FittingEntity,
  TKEvent,
  // FIXTUREATTRS as FX,
  CATALOGUE_CATEGORY as FT,
  CATALOGUE_TYPE,
  LENGTH_FIELD,
  LENGTH_STR_FIELD,
} from '../types';
import {
  getDimensionBy,
  setDimensionBy,
  getSystemBy,
} from '../types/dimension-setting';

/**
 * Add Pipe Fitting
 * @param selectType
 * @param lazyCloseHandler
 * @returns
 */
export const usePipeModal = (
  selectType: string,
  lazyCloseHandler: (lastType?: string) => void,
) => {
  const lastSystem = getSystemBy(selectType) || '';
  // system belonged
  const [currentSystem, setCurrentSystem] = useState<string>(lastSystem);
  const [currentType, setCurrentType] = useState(selectType); // element type
  // shape is complex to decide, so move to an effect to check!
  // kebab case field pairs
  const [fieldProps, setFieldProps] = useState<{ [key: string]: string }>({});
  const pipeFields = findFieldsByType(currentType);

  /**
   * figure out `fieldProps` from last used symbol setting
   */
  useEffect(() => {
    if (!selectType) return;
    // use cached dimension values or symobl detail
    const values = getDimensionBy(selectType);
    const fieldPairs = _.pick(values, pipeFields) as GenericObject;
    setFieldProps(fieldPairs); // construct key/value group
    // console.log(`>>> cached values for symbol:`);
    // console.log(values);

    // *** reset system property: ***
    const { system } = values;
    if (!system) {
      // console.warn(`## no system value!`);
      return;
    }
    // FIXME: check if system has a `/` to ensure it has,
    // in order to display its value with the right option
    // @2023/06/23
    const strSystem = system as string;
    const hasNoSlash = !strSystem.includes('/');
    const withSlash = [...strSystem].join('/');
    // add '/' manually!
    if (hasNoSlash) return setCurrentSystem(withSlash);
    return setCurrentSystem(strSystem);
  }, [selectType, pipeFields]);

  /**
   * TODO: anyway to separate this function out of this hook? - 2023/07/13
   *
   * figure out the properties needed for each field input component
   *
   * @param fieldName each field name
   * @returns normal object
   */
  const inputFieldRenderer = (fieldName: string): InputRendererProps => {
    const kebabField = kebabCase(fieldName);
    const fieldValueChangeHandler = (key: string, value: string) => {
      // console.log({ key });
      const changedProps = { ...fieldProps, [key]: value };
      setFieldProps(changedProps);
    };
    const isLengthField = fieldName === LENGTH_FIELD;
    const isLengthInString = fieldName === LENGTH_STR_FIELD;
    const inputProps: InputRendererProps = {
      ...fieldProps,
      disabled: isLengthInString,
      fieldName, // `DefaultInput` need this
      fieldLabel: isLengthField ? 'LENGTH(in pixels)' : '',
      fieldValue: fieldProps[kebabField], // `DefaultInput` need this
      fieldHandler: fieldValueChangeHandler,
      blurHandler: () => null, // FIXME: placeholder for now - 2023/11/08
    };

    return inputProps;
  };

  const createFeatureEntity = (type: string): FittingEntity => {
    const icon = iconFinder(type);
    const detail: FittingEntity = {
      category: FT.P,
      system: currentSystem,
      entityType: type,
      itemType: CATALOGUE_TYPE.F,
      icon,
      toolType: FT.P,
      ...fieldProps, // add current inputs
    };

    return detail;
  };

  const applyNewElementHandler = () => {
    const detail = createFeatureEntity(currentType);
    // notify map to start drawing ...
    const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
    document.dispatchEvent(event);

    // cache input
    selectType && setDimensionBy(selectType, fieldProps);

    // close
    lazyCloseHandler(currentType);
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    lazyCloseHandler();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  return {
    currentType,
    currentSystem,
    fieldProps,
    pipeFields,
    inputFieldRenderer,
    setCurrentType,
    setCurrentSystem,
    applyNewElementHandler,
    cancelNewElementHandler,
  };
};
