import * as _ from 'lodash-es';
import { configureAuth } from 'react-query-auth';

import { TK_USER_ME, API_CTX, isProdEnv } from '@/config';
import { axios, resetBaseURL, errorResponseToInfo } from '@/lib/axios-v1';
import {
  User,
  AuthUser,
  AuthResponse,
  UserResponse,
  RegisterValues,
  RegisterCredentialsDTO,
  LoginCredentialsDTO,
  ForgotPasswordDTO,
  PasswordResetResponse,
} from '@/types';
import { error, log, warn, uploadException } from '@/utils/logger';
import storage from '@/utils/storage';

import { LoginInput } from './zod';

/**
 * FIXME: this function is re-used by:
 * - ContributorMenu
 * - useForgotPasswordAPI
 * so, put it here!
 * hopefully move it to auth feature
 * @param data
 * @returns
 */
export const requestPasswordReset = (
  data: ForgotPasswordDTO,
): Promise<PasswordResetResponse> => {
  return resetBaseURL().post(`${API_CTX}/password-reset/request`, data);
};

/**
 * === getUser with error handling =====
 * @date 2024/08/14
 * @returns
 */
const getTKUser = async (): Promise<AuthUser> => {
  try {
    return resetBaseURL().get(TK_USER_ME);
  } catch (error) {
    const { message } = errorResponseToInfo(error);
    const err = new Error(message);
    // FIXME: do not upload this error, unnecessary!
    // @2024/08/24
    uploadException(err, true);
    throw err;
  }
};

const loginTK = (data: LoginInput): Promise<UserResponse> => {
  return resetBaseURL().post(`${API_CTX}/login`, data);
};

// === TK register function ===
export const registerTKAccount = (
  data: RegisterCredentialsDTO,
): Promise<UserResponse> => {
  return resetBaseURL().post(`${API_CTX}/users`, data);
};

export function handleUserResponse(data: UserResponse) {
  const { token, ...user } = data;
  if (token) {
    // update token in browser cache
    storage.setToken(token);
    storage.setLoginTime();
  }
  return user;
}

/**
 * Fetch user info with cached token, result could be `null` if token expired.
 * Stronger way to load user through token, with `try...catch`.
 *
 * @returns user | null
 */
async function userCheckWithToken(): Promise<AuthUser | null> {
  const isTestingMode = !!window['TESTING_MODE'];
  if (isTestingMode) {
    log(`>>> Running get TKUser() in testing ...`);
    return getTKUser();
  }

  // check token expiry first
  const isExpired = storage.isTokenExpired();
  if (isExpired) {
    const lastLogin = storage.getLoginTime();
    log(`### using expired token, last login time is: ${lastLogin}`);
    // if expired detected, return null
    return null;
  }
  if (storage.getToken()) {
    // FIXME: trying to get logged in user info with a cached token ...
    // null returned, if token is expired
    // @2023/09/07
    try {
      const response = await getTKUser();
      return handleUserResponse(response);
    } catch (err) {
      error(`## GOT error on fetching user!!!`);
      return null;
    }
  }
  log('## No token no user! goto login...');
  return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  try {
    const response = await loginTK(data);
    return handleUserResponse(response);
  } catch (error: any) {
    console.error(error);
    return null;
  }
}

async function registerFn(data: RegisterValues): Promise<AuthUser | null> {
  const { email, name, password } = data;
  const response = await registerTKAccount({ email, name, password });
  const user = handleUserResponse(response);
  return user;
}
/**
 * close backend session
 * @date 2024/11/22
 * @returns void
 */
const logoutFromSystem = (): Promise<void> => {
  return resetBaseURL().post(`${API_CTX}/logout`);
};

async function logoutFn() {
  // first, logout with token
  await logoutFromSystem();
  // then, clear useless token
  storage.clearToken();
  // last, go back to login
  window.location.assign(window.location.origin);
}

// === for TaksoAI ===
const authConfigTK = {
  userFn: userCheckWithToken,
  loginFn,
  registerFn,
  logoutFn,
};

export const { useUser, useLogin, useLogout, useRegister, AuthLoader } =
  configureAuth(authConfigTK);

/**
 * Wrapper for useUser
 * @returns
 */
export const useAuth = () => {
  const userResp = useUser();
  return userResp.data;
};

export const getCompanyIdFrom = (
  user: AuthUser | undefined | null,
): number | undefined => {
  if (!user) return undefined;

  // FIXME: check company role for new user
  // @2024/03/05
  const orgRoles = _.get(user, 'company_roles', []);
  if (!orgRoles.length) {
    // toaπst.warn(NO_COMPANY_ROLE_ASSIGNED);
    warn('>> No company role attached!');
    return undefined;
  }
  // company_roles could be [] if user not assigned
  const companyId = _.get(user, 'company_roles[0].company.id');
  if (!companyId) {
    // toast.warn(NO_COMPANY_ROLE_ASSIGNED);
    return undefined;
  }
  return companyId;
};

export const checkCompanyAvailable = (
  user: AuthUser | undefined | null,
): boolean => {
  if (!user) return false;

  const orgRolse = _.get(user, 'company_roles', []);
  return !!orgRolse.length || !isProdEnv;
};
