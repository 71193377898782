import * as _ from 'lodash-es';
import { useState, ChangeEvent, useCallback, useEffect } from 'react';

import { kebabCase, moveElementToBack } from '@/utils';

import { InputRendererProps } from '../inputs';
import { iconFinder, shapeFinder, findFieldsByType } from '../store';
import {
  GO,
  FittingEntity,
  TKEvent,
  DUCTSHAPES as SP,
  CATALOGUE_CATEGORY as FT,
  CATALOGUE_TYPE,
  OMIT_FROM_ROUND,
  OMIT_FROM_SQUARE,
  ductFieldsOmmitedBy,
  FIELD_L0,
  LENGTH_FIELD,
  LENGTH_STR_FIELD,
  VERTICAL_LENGTH_FIELD,
} from '../types';
import {
  getDimensionBy,
  setDimensionBy,
  getSystemBy,
} from '../types/dimension-setting';

/**
 * Add Duct Fitting
 * @param selectType
 * @param lazyCloseHandler
 * @returns
 */
export const useDuctModal = (
  /** user selected item type from popup menu */
  selectType: string,
  lazyCloseHandler: (itemType?: string) => void,
) => {
  const lastSystem = getSystemBy(selectType) || '';
  const [currentSystem, setCurrentSystem] = useState<string>(lastSystem); // system belonged
  const [currentType, setCurrentType] = useState(selectType); // symbol type in capitalized

  // get fields first:
  const fieldsByType = findFieldsByType(selectType);
  const [ductFields, setDuctFields] = useState(fieldsByType); // raw fields from config
  const [useShape, setUseShape] = useState(shapeFinder(selectType));

  // == check last used dimension to determine current values:
  // == this will only take effect in modal openning!
  const lastDimension = getDimensionBy(selectType);
  const lastShape = lastDimension.shape || (useShape ? SP.ROUND : '');
  const [shapeType, setShapeType] = useState(lastShape);
  const lastFieldPairs = _.pick(lastDimension, fieldsByType) as GO;
  const [fieldProps, setFieldProps] = useState(lastFieldPairs);

  /**
   * Realtime field values reset when switch `itemType` in modal
   * @date 2025/03/06
   */
  useEffect(() => {
    const lastDimension = getDimensionBy(currentType);
    const { shape, ...values } = lastDimension;
    // if shape cached:
    shape && setShapeType(shape);
    // if values cached:
    values && setFieldProps(values);
  }, [currentType]);

  /**
   * infer fields to show by `shape`
   */
  const ductFieldsFilterBy = useCallback(
    (shape: string) => {
      // re-order fields for `vertical_length` property
      // @2025/0228
      const reOrderVerticalLength = (fields: string[]) => {
        const vlIndex = fields.indexOf(VERTICAL_LENGTH_FIELD);
        const lIndex = fields.indexOf(FIELD_L0);
        if (vlIndex > -1) {
          return moveElementToBack(fields, vlIndex) as string[];
        }
        if (lIndex > -1) {
          // @2025/03/07
          return moveElementToBack(fields, lIndex) as string[];
        }
        return fields;
      };

      let filteredFields = ductFields;
      if (shape === SP.ROUND) {
        filteredFields = _.difference(ductFields, OMIT_FROM_ROUND);
      }
      if (shape === SP.SQUARE) {
        filteredFields = _.difference(ductFields, OMIT_FROM_SQUARE);
      }
      // re-ordered fields
      return reOrderVerticalLength(filteredFields);
    },
    [ductFields],
  );

  /**
   * TODO: anyway to separate this function out of this hook? - 2023/07/13
   *
   * figure out the properties needed for each field input component
   *
   * @param fieldName each field name
   * @returns normal object
   */
  const inputFieldRenderer = (fieldName: string): InputRendererProps => {
    const kebabField = kebabCase(fieldName);
    const fieldValueChangeHandler = (key: string, value: string) => {
      // console.log({ key });
      const changedProps = { ...fieldProps, [key]: value };
      setFieldProps(changedProps);
    };
    const isLengthField = fieldName === LENGTH_FIELD;
    const isLengthInString = fieldName === LENGTH_STR_FIELD;
    const inputProps: InputRendererProps = {
      ...fieldProps,
      disabled: isLengthInString,
      fieldName, // `DefaultInput` need this
      fieldLabel: isLengthField ? 'LENGTH(in pixels)' : '',
      fieldValue: fieldProps[kebabField], // `DefaultInput` need this
      fieldHandler: fieldValueChangeHandler,
      blurHandler: () => null, // FIXME: placeholder for now - 2023/11/08
    };

    return inputProps;
  };

  const shapeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setShapeType(event.target.value);
  };

  /**
   * figure out the attributes displayed on the right side panel
   * @returns `{}`
   */
  const createFeatureEntity = (id?: string): FittingEntity => {
    const icon = iconFinder(currentType);
    // consider shape field
    const fieldsWithShape = useShape
      ? { shape: shapeType, ...fieldProps }
      : fieldProps;
    // ignore unused attributes
    const fieldsOmmited = ductFieldsOmmitedBy(shapeType);
    const pickedProperties: {
      [key: string]: string;
    } = _.omit(fieldsWithShape, fieldsOmmited);

    const detail: FittingEntity = {
      category: FT.D,
      itemType: CATALOGUE_TYPE.F,
      system: currentSystem,
      entityType: currentType || 'UNDEFINED',
      icon,
      toolType: FT.D,
      ...pickedProperties,
    };

    if (id) {
      detail.id = id;
    }

    return detail;
  };

  /**
   * doing 2 jobs:
   *
   * 1. notify map to draw new element with attributes object
   * 2. cache input values to reuse
   */
  const applyNewElementHandler = () => {
    const detail = createFeatureEntity();
    // notify map to start drawing ...
    const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
    document.dispatchEvent(event);

    // cache shape field
    const fieldsWithShape = useShape
      ? { shape: shapeType, ...fieldProps }
      : fieldProps;
    // cache input
    setDimensionBy(currentType, fieldsWithShape);

    // ! save this type to global filter
    // @2025/03/25
    lazyCloseHandler(detail.entityType);
  };

  const ductTypeChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setCurrentType(event.target.value);
    setDuctFields(findFieldsByType(event.target.value));
    setUseShape(shapeFinder(event.target.value));
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    lazyCloseHandler();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  return {
    currentSystem,
    currentType,
    shapeType,
    useShape,
    ductFields: ductFieldsFilterBy(shapeType),
    fieldProps, // exposed at 2024/02/28
    setCurrentSystem,
    ductTypeChangeHandler,
    inputFieldRenderer,
    applyNewElementHandler,
    shapeChangeHandler,
    cancelNewElementHandler,
  };
};
