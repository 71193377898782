export enum SUMMARY_XP_FORMAT {
  C = 'CSV',
  X = 'EXCEL',
  P = 'PDF',
  /** @deprecated - 2025/01/24 */
  T = 'PROJECT',
  E = 'xls',
}

export enum EXPORT_REPORT_SCALE {
  D = 'drawing',
  P = 'project',
}

export enum EXPORT_REPORT_LAYOUT {
  S = 'single-column',
  M = 'multi-column',
}

/**
 * @deprecated in favor of `PANEL`
 */
export enum FEATUREPANEL {
  S = 'SINGLE_FEATURE',
  M = 'BATCH_FEATURE',
}

export enum PANEL {
  S = 'SINGLE_REVIEW',
  B = 'BATCH_REVIEW',
  T = 'TOTAL_REVIEW',
}

export enum DimensionSuffix {
  /** other dimension such as width, height, diameter */
  MM = 'mm',
  /** other dimension such as width, height, diameter */
  I = 'in',
  /** for length string in metric */
  M = 'm',
  /** for length string in imperial */
  FT = 'ft',
}

export enum PAGE_UNIT {
  M = 'metric',
  I = 'imperial',
}
/**
 * move to here and use new type: P in PAGE_UNIT
 * @date 2024/10/29
 */
export const unitSuffixs: { [P in PAGE_UNIT]: string } = {
  [PAGE_UNIT.M]: 'M',
  [PAGE_UNIT.I]: 'Ft',
};

export enum SPECIAL_JOB_ITEM {
  RECT_DUCT = 'RECTANGULAR_DUCT',
  /** sub-type of FITTING */
  OFFSET = 'OFFSET_ELBOW',
  EQMT = 'EQUIPMENT',
  FLOW = 'DIRECTION_OF_FLOW',
  LABEL = 'LABEL',
  TJCT = 'T_JUNCTION',
  EBPI = 'ELBOW_PIPING',
}

export enum SYMBOL_MODE {
  /** symbols after saved */
  INI = 'INITIAL',
  /** new dot, line */
  NEW = 'NEW',
  /** dimension property updated, or being confirmed */
  MOD = 'MODIFIED',
  /** dot or symbol deleted */
  DEL = 'DELETED',
  /** coordinate changed */
  MOV = 'TRANSLATED',
  /** both coordinates and properties changed */
  MIX = 'MOD_AND_MOV',
  /** resize a segment */
  SMS = 'SEG_MOD_SIZ',
  /** segment property change */
  SMP = 'SEG_MOD_PRO',
  /** resize and change properties */
  SMX = 'SEG_MOD_MIX',
  /** delete a segment */
  SDE = 'SEG_DELETE',
}

/**
 * connected to `versionPalette` of `styleUtils.ts`
 */
export enum SYMBOL_REVISION {
  /**
   * No info at all attached to the symbol except for type:
   * solid & red fill/stroke
   */
  A = '0',
  /**
   * Missing info symbol
   * translucent fill/solid stroke
   */
  B = '1',
  /**
   * Completed info symbol: all the necessary fields are filled
   * 'touched' : 'yellow', that means have made a correction
   * No dimension values or not all dimension values complete,
   * but `system` property available: translucent fill
   */
  C = '2',
  /**
   * All the properties have values: `confirmed`, 'green'
   */
  D = '3',

  /**
   * Manual symbol: dot/line
   * @date 2024/03/19
   */
  M = '4',

  /** === Magic brush used 6 themes === */
  /** red theme */
  R = 'red',
  /** orange theme */
  O = 'orange',
  /** yellow theme */
  Y = 'yellow',
  /** green theme */
  G = 'green',
  /** purple theme */
  P = 'purple',
  /** blue theme */
  BL = 'blue',
}

export enum DETECTION_MODE {
  A = 'AI',
  M = 'MANUAL',
}

export enum CATALOGUE_SRC {
  /** for specific company */
  C = 'CUSTOM',
  /** built-in common item for all */
  S = 'STANDARD',
}

/**
 * === First level: CONNECTION_TYPE ===
 *
 * FITTING | LENGTH | LABEL | UNASSIGNED | OFFSET
 */
export enum CATALOGUE_TYPE {
  F = 'FITTING',
  L = 'LENGTH',
  LB = 'LABEL',
  UN = 'UNASSIGNED',
  OF = 'OFFSET',
}

/**
 * === Second level: DEVICE ===
 *
 * Could be called `DEVICE_TYPE`
 *
 * DUCT | PIPE | EQUIPMENT | OFFSET | PLUMBING
 */
export enum CATALOGUE_CATEGORY {
  D = 'DUCT',
  P = 'PIPE',
  E = 'EQUIPMENT',
  O = 'OFFSET', // FIXME: treat `OFFSET_ELBOW` as a special fitting!
  PLM = 'PLUMBING', // add new category @2024/11/05
}

/**
 * === TO BE OBSOLETE  ===
 *
 * Could be called DEVICE_TYPE
 *
 * DUCT | PIPE
 * @deprecated in favor of `CATALOGUE_CATEGORY`
 */
export enum FITTING_TYPE {
  D = 'DUCT',
  P = 'PIPE',
  E = 'EQUIPMENT',
  O = 'OFFSET', // FIXME: treat `OFFSET_ELBOW` as a special fitting!
}

export enum LENGTH_TYPE {
  P = 'PIPE_LENGTH',
  D = 'DUCT_LENGTH',
}

/**
 * Symbol Categories in review panel top filter, a subset of `FILTERS` below.
 * @2023/12/07
 */
export const SymbolCategoryOptions: {
  /** DUCT | PIPE */
  category: string;
  /** FITTING | LENGTH */
  type: string;
  /** group(filter) name */
  label: string;
}[] = [
  {
    category: CATALOGUE_CATEGORY.D,
    type: CATALOGUE_TYPE.F,
    label: 'Duct Fittings',
  },
  {
    category: CATALOGUE_CATEGORY.P,
    type: CATALOGUE_TYPE.F,
    label: 'Pipe Fittings',
  },
  {
    category: CATALOGUE_CATEGORY.D,
    type: CATALOGUE_TYPE.L,
    label: 'Duct Lengths',
  },
  {
    category: CATALOGUE_CATEGORY.P,
    type: CATALOGUE_TYPE.L,
    label: 'Pipe Lengths',
  },
  {
    category: CATALOGUE_CATEGORY.E,
    type: CATALOGUE_TYPE.UN,
    label: 'Equipment',
  },
  {
    category: CATALOGUE_CATEGORY.PLM,
    type: CATALOGUE_TYPE.F,
    label: 'Plumbing',
  },
  // {
  //   category: FITTING_TYPE.O,
  //   type: CATALOGUE_TYPE.OF,
  //   label: 'Offset',
  // },
];

export enum FILTER_LABELS {
  DEFAULT = 'Select Filter',
  ALL = 'All Symbols',
  DUCTS = 'All Duct Fittings',
  PIPES = 'All Pipe Fittings',
  DLENT = 'All Duct Lengths',
  PLENT = 'All Pipe Lengths',
  EQMT = 'Equipment',
  OFST = 'Offset',
  ALENT = 'All Lengths',
  PLMBS = 'Plumbing',
  MXPIPE = 'Pipe Lengths and Pipe Fittings',
  MXDUCT = 'Duct Lengths and Duct Fittings',
  MXPIBO = `Pipe lengths, Elbows, and T's`,
}

/**
 * Symbol Filters Types
 *
 * Straight Duct - DLENT
 * Straight Pipe - PLENT
 * Pipe fittings - PIPES
 * Duct Fittings - DUCTS
 * Straight Pipe + Pipe Fittings - MXPIPE
 * Straight Duct + Duct Fittings - MXDUCT
 */
export enum FILTERS {
  DEFAULT = 'default',
  ALL = 'all',
  /** duct fittings */
  DUCTS = `${CATALOGUE_CATEGORY.D}|${CATALOGUE_TYPE.F}`,
  /** pipe fittings */
  PIPES = `${CATALOGUE_CATEGORY.P}|${CATALOGUE_TYPE.F}`,
  /** straight duct, aka DUCT Length */
  DLENT = `${CATALOGUE_CATEGORY.D}|${CATALOGUE_TYPE.L}`,
  /** straight pipe, aka PIPE Length */
  PLENT = `${CATALOGUE_CATEGORY.P}|${CATALOGUE_TYPE.L}`,
  /** all equipment */
  EQMT = `${CATALOGUE_CATEGORY.E}|${CATALOGUE_TYPE.UN}`,
  /** all offset */
  OFST = CATALOGUE_CATEGORY.O,
  /** all the length */
  ALENT = 'straight_line',
  /** all plumbings */
  PLMBS = `${CATALOGUE_CATEGORY.PLM}|${CATALOGUE_TYPE.F}`,
  /** Straight Pipe + Pipe Fittings */
  MXPIPE = 'PIPE_FITTING_LENGTH',
  /** Straight Duct + Duct Fittings */
  MXDUCT = 'DUCT_FITTING_LENGTH',
  /**
   * Pipe Length + Pipe Elbows + T_JUNCTIONs
   * @2024/12/19 @2025/02/19
   */
  MXPIBO = 'PIPT_LENGTH_ELBOW',
}

export enum SymbolType {
  RECT = 'rect',
  /** @deprecated */
  POLYGON = 'polygon',
  EQUIPMENT = 'point',
  DOT = 'circle',
  LABEL = 'label',
  /**
   * @deprecated
   * added at 2023/06/20
   */
  OFFSET = 'offset',
  /**
   * for pipe line(length)
   */
  SEGMENT = 'singleline',
  /**
   * For `T-junction` symbol, added at 2024/03/13
   */
  TRIANGLE = 'triangle',
  /**
   * For `Offset` symbol, added at 2024/03/13
   */
  DIAMOND = 'diamond',
}

export enum FEATURESTATE {
  /** this is first state placed on map */
  N = 'normal',
  /** selected state by click */
  A = 'active',
  /** highlighted by mouse over */
  H = 'hovered',
  /** white stroke and fill */
  D = 'disabled',
  /** highlighted by same system */
  L = 'highlighted',
  /** hide size text above the feature */
  T = 'textHidden',
}

export enum FEATUREMODES {
  A = 'auto',
  M = 'modify',
  T = 'translate',
}

export enum DUCTSHAPES {
  ROUND = 'round',
  SQUARE = 'rectangular',
}

export const DUCTSHAPEOPTS = [
  {
    label: 'Round',
    value: DUCTSHAPES.ROUND,
  },
  {
    label: 'Rectangular',
    value: DUCTSHAPES.SQUARE,
  },
];

/**
 * Drawing types decides the AI modules to use
 */
export const PageTypes = [
  'HVAC',
  'Piping',
  'Equipment',
  'HVAC $ Piping',
  'HVAC & Piping & Equipment',
];

export enum AIModules {
  E = 'equipment_type',
  H = 'hvac_type',
  P = 'piping_type',
  PFT = 'piping_fitting_type',
  /** @deprecated */
  T = 'table_type', // table extraction @2023/10/11
  /** plumbing module @2024/11/27 */
  PLM = 'plumbing_type',
}

export const AIModuleOptions = [
  {
    label: 'Equipment',
    value: AIModules.E,
    type: 'checkbox',
  },
  {
    label: 'HVAC',
    value: AIModules.H,
    type: 'checkbox',
  },
  {
    label: 'Plumbing',
    value: AIModules.PLM,
    type: 'checkbox',
  },
  /** @deprecated - 2025/01/14 */
  // TODO: restore this only for staging version
  // {
  //   label: 'Pipe (Beta)',
  //   value: AIModules.P,
  //   type: 'radio',
  // },
  {
    label: 'Pipe Fittings',
    value: AIModules.PFT,
    type: 'checkbox', // TODO: restore this only for staging version
  },
];

/**
 * Not in use
 */
export const PageWithAIModules: { [key: string]: string[] } = {
  HVAC: ['hvac_type'],
  Piping: ['piping_type'],
  Equipment: ['equipment_type'],
  'HVAC & Piping': ['hvac_type', 'piping_type'],
  'HVAC & Piping & Equipment': ['hvac_type', 'piping_type', 'equipment_type'],
};

export enum CONSTRUCTSECTOR {
  A = 'A',
  B = 'B',
}

export enum PROJECTSTATE {
  T = 'TENDERING',
  R = 'REVIEW',
  S = 'SUBMITTED',
  F = 'FINISHED',
  I = 'INACTIVE',
}

export enum FILECONTYPE { // file content type, used as page type
  PDF = 'pdf', // for file
  DEFAULT = 'Other', // unassigned
  HVAC = 'HVAC', // for page
  PIPE = 'Piping', // for page
  HPMIX = 'HVAC and Piping', // for page
  // OTHER = 'Other', // for page
  ELECTRIC = 'electric', // for future...
}

export enum FILESTATUS {
  LOCKED = 'LOCKED',
  PENDING = 'PENDING', // UPLOADED,
  EXTRACTING_PAGES = 'EXTRACTING_PAGES',
  GENERATING_THUMBNAILS = 'GENERATING_THUMBNAILS',
  COMPLETE = 'COMPLETE', // page splitting from pdf done
  ERROR = 'ERROR',
  /** get generated upload url - @2024/10/04 */
  PRESIGNED = 'PRESIGNED',
}

// INIT -> PENDING -> PROCESSING -> COMPLETE | ERROR
export enum JOBSTATUS {
  INIT = 'ISNULL', // to start a job
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  /** this is the only correct value for job completion */
  COMPLETE = 'COMPLETE',
  // COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

/**
 * This decide the ribbon setting bar status
 */
export enum PAGESTATUS {
  INIT = 'UPLOADING', //             1. first stage
  UPLOADED = 'UPLOADED', //          2. page ready to proceed AI job
  ZOOMIFY = 'GENERATING_ZOOMIFY', // 3. ZOOMIFYING ...
  COMPLETE = 'COMPLETED', //         4. zoomyfiy processing completed, tiles & symobols ready to use!
  PROCESSING = 'PROCESSING_AI', //   5. AI RUNNING
  ERROR = 'ERROR', //                6. AI JOB ERROR
  LOCKED = 'LOCKED', //              7. inherited from file,
  INVALID = 'INVALID', // no page selected
  PREALGO = 'PREALGO', // valid to run algo
  REVIEWFITTINGS = 'REVIEWFITTINGS',
  REVIEWSYSTEMS = 'REVIEWSYSTEMS',
  SAVING = 'SAVING', // save changes in review step
  REVIEWED = 'REVIEWD', // editor reviewed after saving
  DONE = 'DONE', // all review works completed! Ready to export to pricing
  INACTIVE = 'INACTIVE',
}

export enum DRAWINGSYMBOL {
  CARBON_DIOXIDE = 'CARBON DIOXIDE',
  CENTER_LINE_REDUCER = 'center line reducer',
  ECCENTRIC_REDUCER = 'eccentric reducer',
  ELBOW = 'elbow',
  FIRE_DAMPER = 'Fire Damper',
  PERIMETER_DIFFUSER = 'perimeter diffuser',
  POLYGON_RECTANGULAR_DUCT = 'Polygon Rectangular Duct',
  RECTANGULAR_DUCT = 'Rectangular Duct',
  RETURN_DUCT = 'Return Duct',
  ROUND_DUCT_DOWN = 'Round duct Down',
  SUPPLY_AIR_DIFFUSER = 'Supply Air Diffuser',
  SUPPLY_OR_OUTSIDE_AIR_DUCT = 'Supply or Outside Air Duct',
  THEROSTAT = 'Therostat',
  Y_JUNCTION = 'Y junction',
}
