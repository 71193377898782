/**
 * Define a general log method to diffrentiate the environments
 *
 * @date 2024/04/03
 */

import { isProdEnv, isDebugMode } from '@/config';
import { reportException } from '@/lib/sentry';

import { formatDateByMDHM } from './format';
import { saveItem, getStoredItem } from './storage';

const storagePrefix = 'taksoai_tool_';

/** measure performance start */
export const measureST = (label: string) => {
  isDebugMode && console.time(label);
};
/** measure performance end */
export const measureED = (label: string) => {
  isDebugMode && console.timeEnd(label);
};

/**
 * General log method will mute output in production environment
 *
 * @param message
 * @param level 0: 'debug' | 1: 'warn' | 2: 'error'
 */
export const log = (message: any, level = 0) => {
  if (!isDebugMode) return;
  if (level == 2) return console.error(message);
  if (level == 1) return console.warn(message);

  if (isProdEnv) return;
  console.log(message);
};

export const warn = (message: any) => log(message, 1);

export const error = (message: any) => log(message, 2);

/**
 * Upload exception directly and controled by config
 * @param error native js error
 */
export const uploadException = (error: Error, ignore?: boolean) => {
  if (ignore) {
    warn(`## ignore exception upload: ${error.message}`);
    return;
  }
  // TODO: check global environmental config ...
  reportException(error);
};

/**
 * Save front-end exception(warning, or error) for diagnose
 *
 * @date 2024/04/09
 * @param src source file name
 * @param messge error or warning message
 */
export const writeException = (
  src: string,
  message: string,
  isError?: boolean,
): void => {
  const key = `${storagePrefix}error`;
  const logs = getStoredItem(key);
  // read logs
  const logObjs = logs ? JSON.parse(logs) : [];
  const time = formatDateByMDHM();
  const error = {
    src,
    message,
    time,
    isError: 0,
  };
  if (isError) error.isError = 1;
  const reduceLogs = [error, ...logObjs].slice(0, 10);
  // write logs
  saveItem(key, JSON.stringify(reduceLogs));

  // console output:
  if (isError) {
    reportException(new Error(message));
    return console.error(message);
  }

  console.warn(message);
};
