/**
 * Dom event listeners
 * @date 2024/04/08
 */

import { Size } from 'ol/size';

import { KEY_CODES } from '@/types/options';
import { log, warn } from '@/utils/logger';

import { domContextMenuHandler as popupCxtMenuOnMap } from '../handlers/domCxtMenuHandler';
import { BPMapEditorAPI } from '../map/BPMapAPI';
import {
  USER_VALIDATED_FIELD as UVF,
  GO,
  FittingEntity,
  FILTERS,
} from '../types';
import { BPSymbol } from '../types/symbols';

import * as relayer from './eventsRelayer';

export const CommandListener = {
  /** BPMapEditoer instance */
  _map: {},
  map: function () {
    return this._map as BPMapEditorAPI;
  },
  /**
   * transient symbol corrections - to sync & save immediately when review panel closed
   * ! need a `{}` as placeholder...
   * @2025/02/21
   */
  _corrections: [{}],
  addSilentCorrection: function (detail: GO) {
    this._corrections.push(detail);
  },
  getSilentCorrections: function () {
    return this._corrections.filter((c) => Object.hasOwn(c, 'id'));
  },
  clearSilentCorrections: function () {
    this._corrections.length = 0;
  },
  /** drawing symbols */
  _symbols: [{}],
  symbols: function () {
    return this._symbols as BPSymbol[];
  },
  /** if editor is locked */
  _locked: false,
  locked: function () {
    return this._locked;
  },
  /** drawing id */
  _pageId: '',
  pageId: function () {
    return this._pageId;
  },
  /** if side panel open */
  _reviewPanelOpen: false,
  isReviewPanelOpen: function () {
    return this._reviewPanelOpen;
  },
  /**
   * symbols filter type cache
   * @deprecated its already cached in map instance
   */
  _symbolFilter: FILTERS.ALL as string,
  /**
   * @deprecated NOT useful anymore @2025/03/10
   * @param filter
   */
  setSymbolFilter: function (filter: string) {
    this._symbolFilter = filter;
  },
  /** @deprecated NOT IN USE */
  symbolFilter: function () {
    return this._symbolFilter;
  },
  /** @deprecated */
  resetSymbolFilter: function () {
    this._symbolFilter = FILTERS.ALL;
  },
  /** review panel shouldn't be part of context */
  setReviewPanel: function (open: boolean) {
    this._reviewPanelOpen = open;
  },
  resetReviewPanel: function () {
    this._reviewPanelOpen = false;
  },
  /** =========== set context ==================== */
  setContext: function (
    map: BPMapEditorAPI,
    /** all the symbols of current drawing */
    symbols: BPSymbol[],
    pageId: string,
    locked: boolean,
  ) {
    this._map = map;
    this._symbols = symbols;
    this._locked = locked;
    this._pageId = pageId;
  },
  clearContext: function () {
    this._map = {};
    this._symbols = [];
    this._pageId = '';
    this._locked = false;
  },
  /** ===============EVENT HANDLERS ==================== */
  onWindowBlur: function (event: FocusEvent) {
    this.map().onCtrlKeyReleased(event);
  },
  onWindowFocus: function () {
    // console.log('has focus');
  },
  zoomInDrawingAfterTable: function () {
    setTimeout(() => this.map().zoomBy(4.2), 400);
  },
  delightSymbols: function (event: Event) {
    const { detail } = event as CustomEvent;
    const { system } = detail;
    // this.map().delightSymoblsBy(system);
  },
  hightlightSymbolsBySystem: function (event: Event) {
    const { detail } = event as CustomEvent;
    const { system, color } = detail;
    // this.map().hightlightSymbolsBy(system, color);
  },
  showFeaturesBy: function (event: Event) {
    const { detail } = event as CustomEvent;
    const symbols = detail as BPSymbol[];

    // get possible activated feature
    const activedFeature = this.map().activeFeature;
    // console.log(activedFeature);

    this.map().renderSimple(symbols);

    // no previous highlited feature
    if (!activedFeature) return;

    // active the feature again
    const fid = activedFeature.getId();
    this.map().focusFeature(fid);
  },
  filterFeaturesBy: function ({ detail: type }: CustomEvent) {
    this.map().setFilterType(type);
    this.map().renderSymbols(this.symbols());
  },
  reverseTextDisplay: function ({ detail }: CustomEvent) {
    // if `hide size text` box checked
    this.map().inverseFeatureText(detail);
  },
  reverseFeatures: function ({ detail }: CustomEvent) {
    this.map().inverseFeatureSelection(detail);
  },
  onForceUpdateMap: function (event: Event) {
    this.map().resizeViewport();
  },
  onReCheckFeature: function ({ detail: featureId }: CustomEvent) {
    this.map().focusFeature(featureId);
  },
  onClearCheckFeature: function (event: Event) {
    this.map().deFocusFeature();
  },
  onReviewTotalSymbol: function ({ detail: featureId }: CustomEvent) {
    // FIXME: be careful this offset ratio!
    // @2023/10/12, 17
    const ratio = 3 / 16; // dont larger than this! - @2023/10/17
    // center feature to the left part of map box(viewport)
    const mapSize = this.map().size as Size;
    const offsetX = mapSize[0] * ratio;
    const offsetY = mapSize[1] / 2;
    this.map().flyToFeature(featureId, offsetX, offsetY);
  },
  onReviewFittingFocus: function ({ detail: featureId }: CustomEvent) {
    this.map().flyToFeature(featureId);
  },
  onFeatureAutoMode: function ({ detail }: CustomEvent) {
    this.map().setFeatureMode(detail);
  },
  onFeatureMoveMode: function ({ detail }: CustomEvent) {
    this.map().switchToFeatureTranslate();
    this.map().setFeatureMode(detail);
  },
  onFeatureModifyMode: function ({ detail }: CustomEvent) {
    this.map().switchToFeatureModify();
    this.map().setFeatureMode(detail);
  },
  /**
   * Accept delete event only!
   */
  onFeatureDelete: function ({ detail: id }: CustomEvent) {
    this.map().deleteFeatureBy(id);
    relayer.clearSymbolFromStore(this.pageId(), id);
  },
  /** delete multiple features */
  onDeleteAll: function (symbolIds: string[]) {
    const symbols = this.map().deleteFeatures(symbolIds);
    relayer.clearSymbolsFromStore(this.pageId(), symbols);
  },
  /**
   * Confirm Multiple Features
   */
  onConfirmations: function (symbolIds: string[]) {
    const details = symbolIds.map((id) => ({ id, [UVF]: 1 }));
    this.map().syncBatchFeatureDetail(details as GO[]);
  },
  /**
   * Copy feature from `properties panel`
   */
  onFeatureCopy: function (id: string) {
    const symbolCopy = this.map().copyFeature(id);
    if (!symbolCopy) return warn(`## No symbol copy created!`);
    relayer.addSymbolToStore(this.pageId(), symbolCopy);
  },
  /**
   * confirm feature in property panel, `silent` mode(if need feature panel refresh) considered!
   */
  confirmSymbolHandler: function ({ detail }: CustomEvent) {
    const { id, silent } = detail;
    const confirmed = this.map().isSymbolConfirmed(id);
    if (confirmed) {
      log(`## symbol ${id} already confirmed!`);
      return;
    }
    this.map().correctSymbolSilent({ id, [UVF]: 1 }, 0);
    // If do confirmation from review table, no need to refresh feature panel!
    // @2024/12/03
    if (silent) return;
    this.map().refreshFeatureDetail(id);
  },
  /**
   * correct symbol with one field initialized from review panel table
   * details: {
      id,
      [field]: value,
      completed
    };
   * FIXME: if found `completed: true`, treat it as user_validated!
   * @2024/12/16
   */
  correctSymbolSientHandler: function ({ detail }: CustomEvent) {
    const singleFieldValue = detail as GO;
    // log(`### correct/confirm symbol in silent: ###`);
    // log(detail);
    this.map().correctSymbolSilent(singleFieldValue);
  },
  batchCorrectionHandler: function ({ detail }: CustomEvent) {
    // got a detail list including `symbols` & `page`
    // console.log(detail);
    this.map().syncBatchFeatureDetail(detail as GO[]);
  },
  /**
   * @deprecated in favor of `correctSymbolSientHandler`
   */
  correctElementHandler: function (event: CustomEvent) {
    // const feObject = event.detail as FittingEntity;
    // this.map().correctSymbolWith(feObject);
  },
  applyBrushSettingHandler: function (event: Event) {
    const { detail } = event as CustomEvent;
    this.map().setBrushSetting(detail);
    // remove previous tools setting first...
    this.map().stopAllToolBeforeSwitch(true);
    const brush = document.querySelector('.brush-indicator');
    if (!brush) return warn(`## No brush element existance!`);
    this.map().openBrushPainting(brush as HTMLElement);
  },
  newLineHandler: function (event: Event) {
    const { detail } = event as CustomEvent;
    // remove previous tools setting first...
    this.map().stopAllToolBeforeSwitch(false);
    // remember detail, to check tool type later - @2023/12/20
    this.map().setFittingEntity(detail);
    this.map().setNewLineTool(detail);
  },
  newElementHandler: function (event: CustomEvent) {
    const feObject = event.detail as FittingEntity;
    // remove previous tools setting first...
    this.map().stopAllToolBeforeSwitch(false);
    // display drawer dot
    const drawer = document.querySelector('.map-dot-drawer');
    // cache symbol detail to build new symbol:
    this.map().setFittingEntity(feObject);
    this.map().openDrawingDots(drawer as HTMLElement);
    // ! we dont want to apply filter when a single filter setting not have been used
    // @2025/03/27
    if (this.map().isFilterOff()) return;
    // ! reset filter with the single item-type from new symbol object!
    // @2025/03/25
    const itemType = feObject.entityType;
    this.map().setFilterType(itemType);
    this.map().renderSymbols(this.symbols());
  },
  zoomByLevelHandler: function (event: CustomEvent) {
    const level = event.detail;
    if (level === 'max') return this.map().zoomToMax();
    this.map().zoomBy(level);
  },
  zoomInHandler: function (event: Event) {
    this.map().zoomIn();
  },
  zoomOutHandler: function (event: Event) {
    this.map().zoomOut();
  },
  domContextMenuHandler: function (event: MouseEvent) {
    popupCxtMenuOnMap(this.map(), this.locked())(event);
  },
  /**
   * de-select all the symbols from map by context-menu
   * @deprecated
   */
  deselectAllHandler: function () {
    this.map().deSelectActiveFeatures();
  },
  /** exclude current feature from selection */
  excludeSelectionHandler: function (event: Event) {
    const { detail } = event as CustomEvent;
    this.map().excludeFeatureFromSelection(detail);
  },
  /**
   * safe way to ensure context-menu removed, not invoked by anywhere
   */
  onDomClickHandler: function (event: Event | null) {
    if (!event) return;

    const isCanvasClick = (event.target as HTMLElement).tagName == 'CANVAS';
    // dont remove it when click on non-canvas area!
    // @2025/03/18
    if (!isCanvasClick) return;
    const mCtxMenu = document.querySelector('.map-context-menu');
    mCtxMenu?.classList.add('hidden');
  },
  isCtrlKey: function (event: KeyboardEvent) {
    return (
      event.key === KEY_CODES.CONTROL ||
      event.key === KEY_CODES.META ||
      event.ctrlKey
    );
  },
  keyupHandler: function (event: KeyboardEvent | FocusEvent) {
    // cancel ctrl key status
    this.map().onCtrlKeyReleased(event);

    const currentElement = event.target as HTMLElement;
    // FIXME: // no need to proceed keyboard event
    // @2024/07/05
    if (currentElement.tagName !== 'BODY') return;

    const isKbdEvent = event instanceof KeyboardEvent;
    if (!isKbdEvent) return; // windows blur captured, exit!

    // FIXME: here on keyup can ONLY use checking evetn.key equals to `Meta`
    // instead of `event.metaKey`, its `false` !!! Unbelievable!
    // @2023/12/21(MY BIRTHDAY)
    const isCtrlKey = this.isCtrlKey(event);
    const isDrawingLength = this.map().isDrawing();
    // FIXME: need to check if drawing multiple lines, otherwise cause bug
    // @2024/01/31
    const isLastElbow = this.map().isLastSymbolElbow();

    // delete last fitting for multiple pipe
    if (isCtrlKey && isDrawingLength && isLastElbow) {
      // delete last fitting symbol at the end of pipe ...
      this.map().undoLastSymbolAddition();
      // finish one session of multiple-line drawing
      this.map().finishMultipleLines();
    }
  },
  /**
   * FIXME: only working in `editing` mode, that means do not listen key event while review panel open!
   * @2024/09/26
   *
   * Key down handler only towards editor(drawing) related operation,
   * Do not process other popups and side panels
   *
   * NOTE: another `Escape` keyDownHandler is in `useTotalReviewOpener`
   *
   * @log need to add active line to drawing session while pressed Ctrl key!
   * @date 2024/07/05
   *
   * @param event
   * @returns
   */
  keydownHandler: function (event: KeyboardEvent) {
    // first safety check:
    if (!event.target) return;

    // FIXME: check global lock - @2024/09/25
    if (this.locked()) return;

    // == Checking Ctrl or CMD key pressed ==
    // FIXME: check `Ctrl` key regardless if map is being activated,
    // cos the CTRL key may be pressed before pointer pressed to map,
    // the key may be pressed after new symbol dialog closed
    // @2024/12/19
    if (this.isCtrlKey(event)) {
      this.map().onCtrlKeyPressed();
      // === Add active line to drawing session ===
      this.map().preExtendingLine();
    }

    // close panel & stop drawing symbol!
    if (event.key === KEY_CODES.ESCAPE) {
      // FIXME: do nothing while disabled checked - @2024/05/29
      if (this.map().isFeaturesDisabled()) return;

      // FIXME: rerender all the symbols after `total review panel` closed,
      // and zoom out to a bigger view - 2024/01/30
      // FIXME: do not zoom-out while drawing line
      // @2024/02/06
      if (this.map().isDrawing()) {
        this.map().stopAllToolBeforeSwitch(true);
      }
      return;
    }

    // stop unecessary event if drawing is not activated to delete symbols from canvas!
    const unknowElement = event.target as HTMLElement;
    const isBodyElement = unknowElement.tagName == 'BODY';
    // NOTE: check event source, only be `BODY` to proceed!
    if (!isBodyElement) {
      // console.warn('## Exit key events for none-body element!');
      return;
    }

    // NOTE: Mac use: Backspace, Win use: Delete
    // @2024/09/26
    // FIXME: No need to check event target cos the review panel closed at this point!
    // @2024/10/01
    if (event.key === KEY_CODES.BACKSPACE || event.key === KEY_CODES.DELETE) {
      const toDeleted = this.map().getActiveFeatures();
      // empty check
      if (!toDeleted.length) {
        return console.warn('## No features selected!');
      }

      // === single selection process
      if (toDeleted.length === 1) {
        const featureId = this.map().deleteSelectedFeature() as string;
        // FIXME: check if has changed, in order to figure out if new symbols being deleted
        const hasChanged = this.map().hasChanged();
        // step 2: update smbols store is necessary for changes check on page leave
        relayer.clearSymbolFromStore(this.pageId(), featureId, hasChanged);
        return;
      }

      // === multiple selection process
      const symbolIds = toDeleted.map((f) => f.getId() as string);
      const symbols = this.map().deleteFeatures(symbolIds);
      // FIXME: check if has changed, in order to figure out if new symbols being deleted
      const hasChanged = this.map().hasChanged();
      // notify `useFileDetails` to clear cache
      relayer.clearSymbolsFromStore(this.pageId(), symbols, hasChanged);
    }
  },
};
