import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useLayoutEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { appVersion } from '@/config';
import { AppProvider } from '@/providers/app';

import { createRouter } from './routes';
import { log } from './utils/logger';

const AppRouter = () => {
  // display current version
  console.log(`## Welcome to TaksoAI homepage:`);
  console.log(appVersion);

  const queryClient = useQueryClient();

  const router = useMemo(() => createRouter(queryClient), [queryClient]);

  useLayoutEffect(() => {
    // Remove splash from html - @2024/06/06
    document.getElementById('splash')?.remove();
  }, []);

  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  );
};

function App() {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;
