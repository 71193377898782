/**
 * Add Custom Fitting Modal
 * @date 2024/04/23
 */
import { useState, useRef } from 'react';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { ADD_CUTEOM_FITTING, SELECT_MORE_SYSTEM } from '@/config';

import { useNormalView } from '../hooks/useNormalView';
import { CreateMoreSystems, InputWithWarning, InputAPI } from '../inputs';
import {
  FITTING_TYPE,
  TKSystem,
  TKEvent,
  FittingEntity,
  CATALOGUE_TYPE,
} from '../types';
import {
  getSystemBy,
  getDimensionBy,
  setDimensionBy,
} from '../types/dimension-setting';
import { HELP_ICON } from '../types/icons';

type CustomFittingProps = ModalOpenProps & {
  /**
   * CUSTOM_DUCT_FITTING | CUSTOM_PIPE_FITTING
   */
  selectType: string;
  /**
   * DUCT | PIPE
   */
  category: string;
  systems: TKSystem[];
};

/**
 * Simple Custom Fitting Modal
 *
 * @2024/04/23
 *
 * @returns
 */
export const CustomFittingModal = ({
  isOpen,
  isOnStage,
  category,
  selectType,
  systems,
  lazyCloseHandler,
}: CustomFittingProps) => {
  // check cache first - @2025/3/06
  const lastDimension = getDimensionBy(selectType);
  const [name, setName] = useState(lastDimension.name || '');
  const lastSystem = getSystemBy(selectType) || '';
  const [currentSystem, setCurrentSystem] = useState<string>(lastSystem); // system belonged
  const { isNormalView, setIsNormalView } = useNormalView();

  const focusRef = useRef<InputAPI>(null);

  const focusInut = () => {
    focusRef.current?.focus();
    focusRef.current?.showOutline();
  };

  const onNameValueChange = (_: string, value: string) => {
    setName(value);
    focusRef.current?.hideOutline();
  };

  const applyNewElementHandler = () => {
    // if (!currentSystem) return console.warn('## NO system assigned!');
    // no need to have a system:
    // https://taksoai.atlassian.net/browse/TAK-830
    if (!name) {
      focusInut();
      return;
    }

    const detail: FittingEntity = {
      category,
      system: currentSystem,
      entityType: selectType,
      itemType: CATALOGUE_TYPE.F,
      icon: HELP_ICON,
      toolType: category,
      name,
    };
    // cache name input - @2025/03/6
    setDimensionBy(selectType, { name });

    // notify map to start drawing ...
    const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
    document.dispatchEvent(event);

    // close
    lazyCloseHandler();
  };

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={
        isNormalView ? `Add a Custom ${category} Fitting` : SELECT_MORE_SYSTEM
      }
    >
      {/* == system field == */}
      <CreateMoreSystems
        systems={systems}
        category={category as FITTING_TYPE}
        itemType={selectType}
        currentSystem={currentSystem}
        currentSystemChangeHandler={setCurrentSystem}
        onMoreSystemSwitch={setIsNormalView}
      />
      {/* == name field == */}
      <div className="pt-2 pb-4">
        {isNormalView && (
          <InputWithWarning
            fieldName="name"
            fieldValue={name}
            fieldLabel="NAME"
            fieldHandler={onNameValueChange}
            blurHandler={() => null}
            ref={focusRef}
          />
        )}
      </div>
      {/* button row */}
      <div className="flex gap-x-10 justify-between mx-0 mt-6">
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={() => lazyCloseHandler()}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className="btn-green flex-1 leading-5 text-base bg-green shadow-md"
          onClick={() => applyNewElementHandler()}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
