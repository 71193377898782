import clsx from 'clsx';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { NEW_PLUMBING_MODAL_TITLE, SELECT_PIPE_SYSTEM } from '@/config';

import { useNormalView } from '../hooks/useNormalView';
import { usePlumbingModal } from '../hooks/usePlumbingModal';
import { DuctDynaFields, CreateMoreSystems } from '../inputs';
import { getPlumbings, findItemPropertyBy } from '../store';
import {
  FittingEntity,
  TKSystem,
  BPPage,
  CATALOGUE_CATEGORY as CAT,
} from '../types';

type PlmbModalProps = ModalOpenProps & {
  selectType: string;
  systems: TKSystem[];
  newTypeApplyHandler?: (fe: FittingEntity) => void;
  unit?: string;
  page?: BPPage;
};

/**
 * Add Plumbing Symbol Dialog
 * @2024/11/15
 */
export const AddPlumbingModal = ({
  isOpen,
  isOnStage,
  selectType,
  systems,
  unit,
  lazyCloseHandler,
}: PlmbModalProps) => {
  const {
    currentType,
    currentSystem,
    plumbFields,
    fieldProps,
    setCurrentSystem,
    setCurrentType,
    inputFieldRenderer,
    applyNewElementHandler,
    cancelNewElementHandler,
  } = usePlumbingModal(selectType, lazyCloseHandler);

  const { isNormalView, setIsNormalView, normalView } = useNormalView();

  const hasSystemDefined = findItemPropertyBy(currentType, 'system');

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={isNormalView ? NEW_PLUMBING_MODAL_TITLE : SELECT_PIPE_SYSTEM}
    >
      {/* == system select == */}
      {hasSystemDefined && (
        <CreateMoreSystems
          systems={systems}
          category={CAT.P}
          itemType={selectType}
          currentSystem={currentSystem}
          currentSystemChangeHandler={setCurrentSystem}
          onMoreSystemSwitch={setIsNormalView}
        />
      )}
      {/* select duct type */}
      <label className={normalView('color-primary text-sm block mb-2 mt-2')}>
        PLUMBING TYPE
        <select
          name="province"
          className="block w-full px-2 py-1 mt-1 text-sm frame-input"
          value={currentType}
          onChange={(event) => setCurrentType(event.target.value)}
        >
          {getPlumbings(true).map((plmb) => (
            <option key={plmb.type} value={plmb.type}>
              {plmb.label}
            </option>
          ))}
        </select>
      </label>
      {/* == switch plumbing type ?? == */}
      {/* Dimension fields: diameter - length - height - width *** */}
      <div className={normalView('flex flex-wrap my-0 gap-0')}>
        <DuctDynaFields
          defaultValues={fieldProps}
          fields={plumbFields}
          fieldRenderer={inputFieldRenderer}
          unit={unit}
          itemType={currentType}
        />
      </div>
      {/* button row */}
      <div className={normalView('flex gap-x-10 justify-between mx-0 mt-6')}>
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={cancelNewElementHandler}
        >
          Cancel
        </button>
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base bg-green shadow-md',
          )}
          onClick={applyNewElementHandler}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
