import * as _ from 'lodash-es';

import { swapElements, moveElementToBack } from '@/utils';

import { GO } from './common';
import { EntityGroup, FittingEntity } from './entity';
import { DUCTSHAPES as SP, LENGTH_TYPE } from './options';

export const DEFAULT_ALL_SHORT = 'all';
export const DEFAULT_EMPTIY_OPT = 'empty';

/**
 * TODO: `s_number` may not useful anymore... @2024/11/12
 */
export const fieldsExcludeFromCompletion = [
  's_number', // this is a field of `EQUIPMENT` symbol, ignored to calculate completion
];

export const FIELD_W1 = 'width_1';
export const FIELD_W2 = 'width_2';
export const FIELD_H1 = 'height_1';
export const FIELD_H2 = 'height_2';
export const FIELD_D1 = 'diameter_1';
export const FIELD_D2 = 'diameter_2';
/** from EQUIPMENT symbol */
export const FIELD_ID = 'identifier';
/** from EQUIPMENT sybmol */
export const FIELD_ST = 'subtype';
/** from EQUIPMENT sybmol */
export const FIELD_SN = 's_number';
/** real length of a duct fitting */
export const FIELD_L0 = 'length';

export const LENGTH_FIELD = 'length_1';
export const LENGTH_STR_FIELD = 'length_unit_str';
export const LENGTH_FIELDS = ['length_1'];
export const USER_VALIDATED_FIELD = 'user_validated';
export const VERTICAL_LENGTH_FIELD = 'vertical_length'; // @2024/10/29

/**
 * == Used to check if a property is valid `numeric` dimension field through: ==
 * for `isDimensionField` function
 * Define all the fields used in fittings and pipes...
 * As a field name standard/dict used in codes
 * TODO: Add more fields ...
 */
export enum FIXTUREATTRS {
  D1 = FIELD_D1,
  D2 = FIELD_D2,
  H1 = FIELD_H1,
  H2 = FIELD_H2,
  W1 = FIELD_W1,
  W2 = FIELD_W2,
  VL = VERTICAL_LENGTH_FIELD,
  /** for `Silencer` symbol - @2025/03/07 */
  L0 = FIELD_L0,
}

/**
 * Include all the `string` dimension for review table input
 * FIXME: hard-coded fields from `EQUIPMENT` - @2023/11/17
 * FIXME: add `diffuser_code` for `SUPPLY_AIR_DIFFUSER` - @2024/11/12
 * FIMXE: add `identifier` for `EQUIPMENT` - @2024/12/10
 * FIXME: add `product_code` for TOILET symbol - @2025/01/17
 * FIXME: add `length_unit_str` for `duct_length` - @2025/03/11
 */
export const stringTypeFields = [
  'name',
  'subtype',
  's_number',
  'diffuser_code',
  'identifier',
  'product_code',
  'length_unit_str',
];

export const readonlyFields = [LENGTH_STR_FIELD];

export const coarseUnitSuffix = [VERTICAL_LENGTH_FIELD, FIELD_L0];

/**
 * FIXME: hard-coded round shape ducts used in new duct dialog
 * @date 2024/03/20
 */
export const roundDucts = ['ROUND_DUCT_UP', 'ROUND_DUCT_DOWN'];

/**
 * Hand picked items that have `shape` property, need columns switch by start editing one kind of dimension cell:
 * if picked `diameter`, then `heigt` & `width` will disappear, vs `diamer` disappear
 *
 * NOTE: NOT IN USE BUT KEEP IT!
 * in order to chek dialog inputs
 * @deprecated 2025/03/13
 * @date 2023/11/02
 */
export const itemsWithShapeProperty = [
  'BALANCING_DAMPER',
  'CENTER_LINE_REDUCER',
  'DUCT_LENGTH',
  'ECCENTRIC_REDUCER',
  'ELBOW_HVAC',
  'ELBOW_PIPING',
  'EXHAUST_DUCT',
  'FIRE_DAMPER',
  'MANUAL_BALANCING_DAMPER',
  'MOTORIZED_COMBINATION_FIRE_AND_SMOKE_DAMPER',
  'MOTORIZED_DAMPER',
  'OFFSET_ELBOW',
  'POLYGON_RECTANGULAR_DUCT', // LENGTH
  'RETURN_DUCT',
  'RETURN_DUCT_DOWN',
  'ROUND_DUCT_LENGTH', // LENGTH
  'STRAIGHT_DUCT_LENGTH', // LENGTH
  'SUPPLY_OR_OUTSIDE_AIR_DUCT',
  'T_JUNCTION',
  'Y_JUNCTION',
];

export const OMIT_FROM_ROUND = [
  'height',
  'height_1',
  'height_2',
  'height_3',
  'width',
  'width_1',
  'width_2',
  'width_3',
  `length_1`,
];

export const OMIT_FROM_SQUARE = [
  'angle', // FIXME: add `angle` for `ELBOW_PIPING` symbol @2024/07/02
  'diameter',
  'diameter_1',
  `diameter_2`,
  `diameter_3`,
  `length_1`,
  // ! do not make sense ... @2025/02/27
  // `vertical_length`, // for `ENDPOINT_TYPE_A` @2025/01/14
];

/**
 * re-order EQUIPMENT symbol fields by:
 * [... subtype, identifier, ...]
 * @param dimensionForType fields for equipment symbol
 * @returns
 */
export const reOrderEquipmentFields = (dimensionForType: string[]) => {
  const idField = dimensionForType.find((f) => f === FIELD_ID);
  const noIDFieldS = dimensionForType.filter((f) => f !== FIELD_ID);
  const subTypeIndex = dimensionForType.findIndex((f) => f === FIELD_ST);
  if (idField) {
    noIDFieldS.splice(subTypeIndex, 0, idField);
  }
  return idField ? noIDFieldS : dimensionForType;
};

/**
 * put `diameter_2` to the end of list
 * TODO: MORE...
 * @param dimensionForType
 * @returns
 */
export const reOrderFieldsForSuffix2 = (dimensionForType: string[]) => {
  const diameter2 = dimensionForType.find((f) => f === FIELD_D2);
  const noD2Fields = dimensionForType.filter((f) => f !== FIELD_D2);
  const reOrderedFields = diameter2
    ? [...noD2Fields, diameter2]
    : dimensionForType;
  return reOrderedFields;
};

/**
 * - put `length_unit_str` to the back of `width_1`
 * @date 2024/08/27
 * - put `width_1` ahead of `height_1`
 * @date 2025/01/28
 */
export const reOrderFieldsForLength = (dimensionForType: string[]) => {
  const lengthField = dimensionForType.findIndex((f) => f === LENGTH_STR_FIELD);
  const widthField = dimensionForType.findIndex((f) => f === FIELD_W1);
  const heightField = dimensionForType.findIndex((f) => f === FIELD_H1);
  const vlIndex = dimensionForType.indexOf(VERTICAL_LENGTH_FIELD);
  const lIndex = dimensionForType.indexOf(FIELD_L0);

  let fields4Length = dimensionForType;

  if (lengthField > -1 && widthField > -1 && heightField > -1) {
    const firstSwap = swapElements(dimensionForType, heightField, widthField);
    fields4Length = swapElements(firstSwap, lengthField, widthField);
  }

  if (widthField > -1 && heightField > -1) {
    fields4Length = swapElements(dimensionForType, heightField, widthField);
  }

  if (vlIndex > -1) {
    fields4Length = moveElementToBack(fields4Length, vlIndex);
  }
  if (lIndex > -1) {
    fields4Length = moveElementToBack(fields4Length, lIndex);
  }

  return fields4Length;
};

/**
 * Check if the duct is round, then display width/height input only in new duct dialog
 *
 * @date 2024/03/21
 * @param itemType
 * @returns
 */
export const checkDuctRoundOnly = (itemType: string) => {
  return roundDucts.includes(itemType);
};

/**
 * Exclude the unrelevant fields in calculation of completion
 *
 * @param fields dimension fields to calculate completion
 *
 * @returns necessary fields to calculate
 */
export const filterCompletionFields = (fields: string[]) => {
  return _.difference(fields, fieldsExcludeFromCompletion);
};

/**
 * Safe way to check shape relevant field
 *
 * @param shape could be capitalized format, but no problem
 * @param field
 * @returns if field editable
 */
export const checkFieldBelongBy = (
  shape: string | undefined,
  field: string,
) => {
  if (!shape) return true; // assume its a string field and editable
  if (field === 'system') return true;
  const notFoundInSquare = !OMIT_FROM_ROUND.includes(field);
  const notFoundInRound = !OMIT_FROM_SQUARE.includes(field);
  // shape irrelavant field assume its visible! - 2025/02/27
  if (notFoundInRound && notFoundInSquare) return true;
  const lowerShape = shape.toLowerCase();
  if (lowerShape === SP.ROUND) return OMIT_FROM_SQUARE.includes(field);
  if (lowerShape === SP.SQUARE) return OMIT_FROM_ROUND.includes(field);
  return true;
};

/**
 * Filter out unrelated fields with shape
 *
 * @param fields fields to filter out
 * @param shape shape in use
 * @returns new set of field after shape filter
 */
export const getReducedFieldsByShape = (
  fields: string[],
  shape: string | undefined | '',
) => {
  if (!shape) return fields;
  // FIXME: sometimes the shape value could be captalized @2023/11/21
  const lowerShape = shape.toLowerCase();
  if (lowerShape === SP.ROUND) return _.difference(fields, OMIT_FROM_ROUND);
  if (lowerShape === SP.SQUARE) return _.difference(fields, OMIT_FROM_SQUARE);
  return fields;
};

export const getOppositeFields = (field: string) => {
  if (field.startsWith('diameter')) {
    return ['width_1', 'width_2', 'height_1', 'height_2'];
  }
  if (field.startsWith('width') || field.startsWith('height')) {
    return ['diameter_1', 'diameter_2'];
  }
  return [];
};

/**
 * Get shape related dimension fields
 * @param shape fitting shape: round, or square
 * @returns
 */
export const ductFieldsOmmitedBy = (shape: string) => {
  if (shape.toLocaleLowerCase() === SP.ROUND) return OMIT_FROM_ROUND;
  if (shape.toLocaleLowerCase() === SP.SQUARE) return OMIT_FROM_SQUARE;
  return [];
};

/**
 * check dimension type to decide if the input is `number` type
 * @param field
 * @returns
 */
export const isDimensionField = (field: string) => {
  const dimensions = Object.values(FIXTUREATTRS) as string[];
  return dimensions.includes(field);
};

/**
 * Build new pipe line info from active line
 *
 * @param details pipe line base info
 * @returns
 */
export const cloneLineBaseProperties = (details: GO): GO => {
  const shape = _.toLower(details['shape'] || '');
  const dimension: GO = { shape };
  //  FIXME: check `PIPE_LENGTH` to have diemeter property!
  // @date 2024/07/08
  const isPipeLine = details['entityType'] === LENGTH_TYPE.P;
  if (shape === SP.ROUND || isPipeLine) {
    dimension['diameter_1'] = details['diameter_1'] || '';
  }
  if (shape === SP.SQUARE) {
    dimension['width_1'] = details['width_1'] || '';
    dimension['height_1'] = details['height_1'] || '';
  }
  return {
    category: details['category'],
    entityType: details['entityType'],
    icon: details['icon'],
    system: details['system'],
    toolType: details['toolType'],
    ...dimension,
  };
};

/**
 * TODO: move to `dimension.ts`
 * Organize list of symbols by entity type
 *
 * @param features symbols details list
 * @param includeLength if features have `???_LENGTH` item
 *
 * @returns group symbols by entity type
 */
export const findEntityTypesFromSymbols = (
  features: GO[],
  includeLength = false,
) => {
  const groups: EntityGroup = {};

  features.forEach((f) => {
    const { entityType } = f as FittingEntity;
    // console.log(entityType);
    if (entityType === 'LABEL') return console.warn('## got LABEL!');

    const groupByType = groups[entityType];
    // blank list init
    if (!groupByType) groups[entityType] = [];
    groups[entityType].push(f);
  });

  if (includeLength) {
    return {
      groups,
      types: Object.keys(groups),
    };
  }

  const excludePipeLength = Object.keys(groups).filter(
    (t) => t !== LENGTH_TYPE.D && t !== LENGTH_TYPE.P,
  );

  return {
    groups,
    types: excludePipeLength,
  };
};

/**
 * Find the common value of each field among the symbols
 * @param symbols
 * @param fieldsAfterShapeChecking
 * @returns
 */
export const getCommonValueFromSymbols = (
  symbols: GO[],
  fieldsAfterShapeChecking: string[],
) => {
  // figure out the common values for each field
  const dimensionValuesByField: { [key: string]: Set<string> } = {};
  fieldsAfterShapeChecking.forEach((f) => {
    const values = dimensionValuesByField[f];
    if (!values) dimensionValuesByField[f] = new Set();
    symbols.forEach((detail) => dimensionValuesByField[f].add(detail[f]));
  });
  return fieldsAfterShapeChecking.reduce((prev: GO, curr: string) => {
    const values = dimensionValuesByField[curr];
    prev[curr] = values.size > 1 ? '' : [...values][0];
    return prev;
  }, {});
};

/**
 * Find out common fields derived from `shape`
 * @param symbols
 * @param commonFields
 * @returns
 */
export const getReducedFieldsFromSymbols = (
  symbols: GO[],
  commonFields: string[],
  useSystemField = true,
) => {
  // FIXME: get fields without `length_xxx` field
  // cos `length_xxx` field has no need to correct!
  // @2023/11/17
  const fieldsWithoutLengthX = commonFields.filter(
    (f) => !f.startsWith('length_'),
  );
  const correctedFields = useSystemField
    ? ['system', ...fieldsWithoutLengthX]
    : fieldsWithoutLengthX;
  const shapesFromSymbols = new Set<string>();
  symbols.forEach((smb) => smb.shape && shapesFromSymbols.add(smb.shape));
  if (shapesFromSymbols.size == 0) return correctedFields;

  const theCommonShape = shapesFromSymbols.values().next().value;
  const fieldsFromShape = getReducedFieldsByShape(
    correctedFields,
    theCommonShape,
  );
  return fieldsFromShape;
};
