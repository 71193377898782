import { Feature } from 'ol';
import { Coordinate, distance } from 'ol/coordinate';
import { getHeight, getWidth, getCenter } from 'ol/extent';
import { Polygon, SimpleGeometry, LineString } from 'ol/geom';
import { Pixel } from 'ol/pixel';

import { DimensionSuffix as DS } from './options';
import { SegmentX } from './segment';
import { Segment } from './symbols';

/**
 * User expected angle from ELBOW_PING circle
 * further to our conversation, these are the pipe angles
 * we should be looking for in the AI and the tool:
 * 22.5, 30, 45, 60, 90
 * @date 2024/07/09
 */
const expectedAngles = [22.5, 30, 45, 60, 90];

/**
 * final round function for angle!
 *
 * @date 2024/07/09
 * @param rawAngle that no more than 90!!
 * @returns
 */
const roundAngleResult = (rawAngle: number) => {
  if (rawAngle < 22.5) return 22.5;

  let closestAngle = 0;
  expectedAngles.reduce((prev, expAgl) => {
    const angleDiff = Math.abs(rawAngle - expAgl);
    if (prev > angleDiff) {
      closestAngle = expAgl;
      return angleDiff;
    }
    return prev;
  }, 90);
  return closestAngle;
};

/**
 * Calculate the angle of two lines in degree
 *
 * @param line1 line segment 1
 * @param line2 line segment 2
 * @returns the angle of two line
 */
export const angleBetween2Lines = (line1: SegmentX, line2: SegmentX) => {
  const angle1 = Math.atan2(
    line1.getY1() - line1.getY2(),
    line1.getX1() - line1.getX2(),
  );
  // console.log({ angle1 });
  const angle2 = Math.atan2(
    line2.getY1() - line2.getY2(),
    line2.getX1() - line2.getX2(),
  );
  // console.log({ angle2 });
  const rawAngle = Math.abs(angle1 - angle2) * (180 / Math.PI);
  // console.log(`>>> raw angle: ${rawAngle}`);
  // == find opposite angle value: ==
  let smallerAngle = rawAngle;
  if (rawAngle > 90) smallerAngle = 180 - rawAngle;
  if (rawAngle > 180) smallerAngle = rawAngle - 180;
  // console.log(`>>> sharp angle: ${smallerAngle}`);
  const closestAngle = roundAngleResult(smallerAngle);
  // console.log(`>>> closest angle: ${closestAngle}`);
  return closestAngle;
};

export const offsetSegment = (seg: Segment, offset: number): Segment => {
  const [pt1, pt2] = seg;
  const [x1, y1] = pt1;
  const [x2, y2] = pt2;
  return [
    [x1 + offset, y1 + offset],
    [x2 + offset, y2 + offset],
  ];
};

/**
 * Revised pixel to length formula
 *
 * @date 2024/01/23
 * @param lenghtInPixel
 * @param dpi
 * @returns
 */
export const px2mm = (lenghtInPixel: number, dpi: number) =>
  (lenghtInPixel / dpi) * 25.4;

/**
 * Figure out the real size of symbol length
 *
 * @date at 2024/01/23
 * @param lengthInmm
 * @param isMetric
 * @param scale
 * @returns
 */
export const realLengthWithUnit = (
  lengthInmm: number,
  isMetric = true,
  scale: number,
) => {
  return isMetric
    ? lengthMMToMetricUnitStr(lengthInmm, scale)
    : lengthMMToImperialUnitStr(lengthInmm, scale);
};

export const getLengthOfLine = (feature: Feature) => {
  const lineString = feature.getGeometry() as LineString;
  const segment: Segment = lineStringToSegment(lineString);
  const length_pixels = Math.round(distance(segment[0], segment[1]));
  return length_pixels;
};

export const getLengthOfLineString = (line: LineString) => {
  const segment: Segment = lineStringToSegment(line);
  const length_pixels = Math.round(distance(segment[0], segment[1]));
  return length_pixels;
};

export const lineStringToSegment = (line: LineString): Segment => {
  const [x1, y1] = line.getFirstCoordinate();
  const [x2, y2] = line.getLastCoordinate();
  const head = [Math.round(x1), Math.round(y1)];
  const tail = [Math.round(x2), Math.round(y2)];
  return [head, tail];
};

/**
 * Figure out if two segment are perpendicular
 * @param seg1 segment A
 * @param seg2 segment B
 * @returns
 */
export const isTwoSegmentsPerpendicular = (seg1: Segment, seg2: Segment) => {
  const [pt1, pt2] = seg1;
  const [pt3, pt4] = seg2;
  const slope1 = (pt2[1] - pt1[1]) / (pt2[0] - pt1[0]);
  const slope2 = (pt4[1] - pt3[1]) / (pt4[0] - pt3[0]);

  return slope1 * slope2 == -1;
};

/**
 * METRIC:
 *  length_unit = truncate((length_mm * scale) / 1000, 2)
 *  length_unit_str = str(length_unit) + " m"
 *
 * @param lengthMM length in milimeters
 * @param scale scale for drawing
 */
export const lengthMMToMetricUnitStr = (lengthMM: number, scale: number) => {
  const lengthInMMAfterScale = (lengthMM * scale) / 1000;
  const lengthUnitTruncted = Math.trunc(lengthInMMAfterScale * 100) / 100;
  return `${lengthUnitTruncted} ${DS.M}`;
};

/**
 * IMPERIAL:
 *  length_unit =  truncate(length_mm * scale * (1 / 304.8), 2)
 *  length_unit_str = str(length_unit) + " ft"
 *
 * @param lengthMM length in milimeters
 * @param scale scale for drawing
 */
export const lengthMMToImperialUnitStr = (lengthMM: number, scale: number) => {
  const lengthInInchesAfterScale = (lengthMM * scale) / 304.8;
  const lengthUnitTruncted = Math.trunc(lengthInInchesAfterScale * 100) / 100;
  return `${lengthUnitTruncted} ${DS.FT}`;
};

export const hex2rgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

/**
 * Check if the mouse is inside a polygon
 *
 * @param position mouse coordinate
 * @param polygon ploygon below the mouse
 * @returns true, false
 */
export const isTouchInsidePolygon = (
  position: Coordinate,
  polygon: Polygon,
) => {
  const coordinates = polygon.getCoordinates();
  // compare with each corner point
  const distances = coordinates[0].map((coord) => distance(coord, position));
  const minDistance = Math.min(...distances);
  const threshold = 10; // 10@2024/06/20, 30@2023/12/21
  return minDistance > threshold;
};

/**
 * Check if the mouse is touching two endpoints of a line
 *
 * @param position mouse coordinate
 * @param lineStr LineString
 * @returns true, false
 */
export const isTouchLineEndpoints = (
  position: Coordinate,
  lineStr: LineString,
) => {
  const headCoord = lineStr.getFirstCoordinate();
  const tailCoord = lineStr.getLastCoordinate();
  // console.log(distance(headCoord, tailCoord));
  const diffHead = distance(headCoord, position);
  // console.log({ diffHead });
  const diffTail = distance(tailCoord, position);
  // console.log({ diffTail });
  // FIXME: use `10` for smaller segment
  // @2023/04/06
  // FIXME: make threshold larger(10->20) to make resize line easier
  // @2024/07/26
  // FIXME: even larger (20 -> 24) to easier modify feature
  // @2025/03/16
  const threshold = 24; // outside of the endpoints!
  return diffHead < threshold || diffTail < threshold;
};

/**
 * Check if pressed on the middle of line
 * @param position mouse pressed position
 * @param lineStr line symbol
 * @returns
 */
export const isTouchMiddleOfLine = (
  position: Coordinate,
  lineStr: LineString,
) => {
  const headCoord = lineStr.getFirstCoordinate();
  const tailCoord = lineStr.getLastCoordinate();
  const diffHead = distance(headCoord, position);
  const diffTail = distance(tailCoord, position);
  const threshold = 120; // outside of the endpoints!
  return Math.abs(diffHead - diffTail) < threshold;
};

export const getOtherEndpoint = (
  handlerPostion: Coordinate,
  lineStr: LineString,
): Coordinate => {
  const headCoord = lineStr.getFirstCoordinate();
  const tailCoord = lineStr.getLastCoordinate();
  const diffHead = distance(headCoord, handlerPostion);
  const diffTail = distance(tailCoord, handlerPostion);
  const threshold = 1; // shortest lenth of a line

  // TODO: check the two point very close case?
  if (Math.max(diffHead, diffTail) < threshold) {
    console.warn(`## got very close case!`);
    return handlerPostion;
  }

  if (diffHead > diffTail) {
    // console.log(`>> get tail ...`);
    return headCoord;
  }
  if (diffTail > diffHead) {
    // console.log(`>> get head ...`);
    return tailCoord;
  }

  console.warn(`>>>> diffHead equals to diffTail? <<<<<`);
  console.log({ diffHead });
  console.log({ diffTail });
  return handlerPostion;
};

/**
 * Calculate two points distance
 *
 * @param pixelA pixel A
 * @param coordB pixel B
 * @returns distance of two points
 */
export const distanceBetween = (pixelA: Pixel, pixelB: Pixel) => {
  const diffX = pixelA[0] - pixelB[0];
  const diffY = pixelA[1] - pixelB[1];
  return Math.round(Math.sqrt(diffX * diffX + diffY * diffY));
};

/**
 * Figure out the farest point of polygon from one touch point of it
 * @param geometry rectangle geometry
 * @param touchPoint modify point
 * @returns farest point of polygon
 */
export const getFarestPointOfPolygon = (
  geometry: Polygon,
  touchPoint: Coordinate,
) => {
  const coordinates = geometry.getCoordinates()[0].slice(1);
  const distances: number[] = [];
  coordinates.forEach((coord) => {
    const diff = distance(coord, touchPoint);
    distances.push(diff);
  });
  const maxDistance = Math.max(...distances);
  const indexOfMax = distances.indexOf(maxDistance);
  return coordinates[indexOfMax];
};

/**
 * Helper function only outsie of map class!!
 * @param geometry target geometry
 * @returns
 */
export const calculateCenter = (geometry: SimpleGeometry) => {
  let center: number[] = [];
  let coordinates: Coordinate[] | null = null;
  let minRadius: number;
  const type = geometry.getType();
  if (type === 'Polygon') {
    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = (geometry as Polygon).getCoordinates()[0].slice(1);
    coordinates.forEach(function (coordinate) {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    center = [x / i, y / i];
  } else if (type === 'LineString') {
    center = (geometry as LineString).getCoordinateAt(0.5);
    coordinates = geometry.getCoordinates();
  } else {
    // whats this? -- point feature
    // console.warn(`got unknown geometry type: ${type}`);
    center = getCenter(geometry.getExtent());
  }

  let sqDistances: number[] = [];
  if (coordinates) {
    sqDistances = coordinates.map(function (coordinate) {
      const dx = coordinate[0] - center[0];
      const dy = coordinate[1] - center[1];
      return dx * dx + dy * dy;
    });
    minRadius = Math.sqrt(Math.max(...sqDistances)) / 3;
  } else {
    minRadius =
      Math.max(
        getWidth(geometry.getExtent()),
        getHeight(geometry.getExtent()),
      ) / 3;
  }
  return {
    center,
    coordinates,
    minRadius,
    sqDistances,
  };
};

/**
 * figure out a thin rectangle around a line with two points
 *
 */
export const calculateRectWithTwoPoints = (
  ptA: Coordinate,
  ptB: Coordinate,
): number[][] => {
  // 1. figure out the horizontal and vertical line case
  const threshold = 4;
  const lineWidth = 4;
  const diffX = Math.abs(ptA[0] - ptB[0]);
  const diffY = Math.abs(ptA[1] - ptB[1]);

  // presume its a horizontal line
  const isHoriLine = diffY < threshold;
  const isVertiLine = diffX < threshold;

  console.log({ isHoriLine });

  if (isHoriLine) {
    return [
      [ptA[0], ptA[1] + lineWidth],
      [ptA[0], ptA[1] - lineWidth],
      [ptB[0], ptB[1] + lineWidth],
      [ptB[0], ptB[1] - lineWidth],
      [ptA[0], ptA[1] + lineWidth],
    ];
  }

  console.log({ isVertiLine });
  // presume its a vertical line
  if (isVertiLine) {
    [
      [ptA[0] - lineWidth, ptA[1]], // top-left
      [ptA[0] + lineWidth, ptA[1]], // top-right
      [ptB[0] - lineWidth, ptB[1]], // btm-left
      [ptB[0] + lineWidth, ptB[1]], // btm-right
      [ptA[0] - lineWidth, ptA[1]], // top-left
    ];
  }
  // 2. now its normal line equation
  const slope = diffY / diffX;
  // 3. get the Perpendicular Line equation
  const slope4Pline = -1 / slope;

  // 4. calculate two left points
  // lineWidth^2 = (x - ptA[0]) * (x - ptA[0]) + (y - ptA[1]) * (y - ptA[1]);
  // const pointYfromA = (x: number) =>
  //   Math.sqrt(lineWidth * lineWidth - (x - ptA[0]) * (x - ptA[0])) + ptA[1];

  // merge to one equation with same y:
  // (slope4Pline * x + c4Pline - ptA[1])^2 = lineWidth^2 - (x - ptA[0])^2

  // expand LEFT:
  // slope4Pline^2 * x^2 + 2 * x * slope4Pline * (c4Pline - ptA[1]) + (c4Pline - ptA[1])^2

  // expand RIGHT:
  // - x^2 + 2 * x * ptA[0] + lineWidth^2 - ptA[0]^2

  // merge again:
  // x^2 * (slope4Pline^2 + 1) + x * (2 * slope4Pline * (c4Pline - ptA[1]) - 2 * ptA[0]) + (c4Pline - ptA[1])^2 - lineWidth^2 + ptA[0]^2 = 0

  const calculateHeadPoints = (pt: Coordinate) => {
    const P2 = (x: number) => Math.pow(x, 2);
    const equation4Pline = (x: number) => slope4Pline * x + c4Pline;
    const c4Pline = pt[1] - slope4Pline * pt[0];
    const qA = P2(slope4Pline) + 1;
    const qB = 2 * (slope4Pline * (c4Pline - pt[1]) - pt[0]);
    const qC = P2(c4Pline - pt[1]) - P2(lineWidth) + P2(pt[0]);

    const x1 = (-qB + Math.sqrt(P2(qB) - 4 * qA * qC)) / (2 * qA);
    const x2 = (-qB - Math.sqrt(P2(qB) - 4 * qA * qC)) / (2 * qA);

    return [
      [x1, equation4Pline(x1)],
      [x2, equation4Pline(x2)],
    ];
  };

  // 5. calculate two right points
  const [p1, p2] = calculateHeadPoints(ptA);
  const [p3, p4] = calculateHeadPoints(ptB);

  // 6. figure out extent
  return [p1, p2, p4, p3, p1];
};

type PT = { x: number; y: number };

export const coord2PT = (coords: Coordinate[]): PT[] => {
  return coords.map((coord) => ({ x: coord[0], y: coord[1] }));
};

export const ray_casting = (point: Coordinate, polygon: Coordinate[]) => {
  const n = polygon.length,
    x = point[0],
    y = point[1];

  let x1,
    x2,
    y1,
    y2,
    count = 0;

  for (let i = 0; i < n - 1; ++i) {
    x1 = polygon[i][0];
    x2 = polygon[i + 1][0];
    y1 = polygon[i][1];
    y2 = polygon[i + 1][1];

    if (y < y1 != y < y2 && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1) {
      count += 1;
    }
  }

  return count % 2 === 0 ? false : true;
};

export const insidePolygon = (point: Coordinate, vs: Coordinate[]) => {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  const x = point[0],
    y = point[1];

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];

    const intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    console.log({ intersect });
    if (intersect) inside = !inside;
  }

  return inside;
};

// not working....
export const pointIsInPoly = (p: PT, polygon: PT[]) => {
  let minX = polygon[0].x,
    maxX = polygon[0].x;
  let minY = polygon[0].y,
    maxY = polygon[0].y;
  for (let n = 1; n < polygon.length; n++) {
    const q = polygon[n];
    minX = Math.min(q.x, minX);
    maxX = Math.max(q.x, maxX);
    minY = Math.min(q.y, minY);
    maxY = Math.max(q.y, maxY);
  }

  console.log(p);
  console.log(polygon);

  if (p.x < minX || p.x > maxX || p.y < minY || p.y > maxY) {
    console.log('>>> first round false');
    return false;
  }

  console.log('>>>>>>>> gon on ...');

  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    if (
      polygon[i].y > p.y != polygon[j].y > p.y &&
      p.x <
        ((polygon[j].x - polygon[i].x) * (p.y - polygon[i].y)) /
          (polygon[j].y - polygon[i].y) +
          polygon[i].x
    ) {
      console.log('one round!');
      isInside = true;
    }
  }

  return isInside;
};
