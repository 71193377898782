import { PlusCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { useState } from 'react';

import saveIcon from '@/assets/icons/entypo_save.svg';
import deleteIcon from '@/assets/icons/fluent_delete-24-filled.svg';
import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { Spinner } from '@/components/ui/spinner';
import { EQUIPMENT_SETTINGS, INPUT_MAX_6 } from '@/config';

import { EquipmentTag } from '../types';

const EquipmentRow = ({
  index,
  equipment,
  onEquipmentChange,
  onEquipmentSave,
  onEquipmentDelete,
}: {
  index: number;
  equipment: EquipmentTag;
  onEquipmentChange: (index: number, eqmt: EquipmentTag) => void;
  onEquipmentSave: (eqmt: EquipmentTag) => Promise<void>;
  onEquipmentDelete: (tag: string, index?: number) => void;
}) => {
  const [saving, setSaving] = useState(false);

  const savingEquipmentTag = async () => {
    // FIXME: check empty tag input for issue:
    // https://taksoai.atlassian.net/browse/TAK-341
    // @2023/08/21
    if (!equipment.tag) return;

    setSaving(true);
    await onEquipmentSave(equipment);
    setSaving(false);
  };

  return (
    <div className="equipment-row flex gap-2 mb-2">
      {/* equipment tag */}
      <input
        type="text"
        name="equipmentTag"
        placeholder={INPUT_MAX_6}
        className={clsx(
          'w-32 px-2 py-1 text-xs frame-input rounded ',
          equipment.project_id ? 'bg-gray-100 cursor-default' : '',
        )}
        disabled={equipment.default}
        value={equipment.tag}
        readOnly={!!equipment.project_id}
        maxLength={6}
        onChange={(event) => {
          onEquipmentChange(index, {
            ...equipment,
            tag: event.target.value,
          });
        }}
      />
      {/* separator */}
      <select
        name="separator_1"
        className="medium block px-2 py-1 text-xs frame-input rounded"
        disabled={equipment.default}
        value={equipment.separator_1}
        onChange={(event) => {
          onEquipmentChange(index, {
            ...equipment,
            separator_1: event.target.value,
          });
        }}
      >
        <option value="-">-</option>
        <option value=".">.</option>
      </select>
      {/* sub type */}
      <select
        name="has_subtype"
        className="medium block px-2 py-1 text-xs frame-input rounded"
        disabled={equipment.default}
        value={`${equipment.has_subtype}`}
        onChange={(event) => {
          onEquipmentChange(index, {
            ...equipment,
            has_subtype: event.target.value == 'true',
          });
        }}
      >
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
      {/* separator */}
      {/* <select
        name="separator_2"
        className="w-20 block px-2 py-1 text-xs frame-input rounded small"
        disabled={equipment.default}
        value={equipment.separator_2}
        onChange={(event) => {
          onEquipmentChange(index, {
            ...equipment,
            separator_2: event.target.value,
          });
        }}
      >
        <option value="-">-</option>
        <option value=".">.</option>
      </select> */}
      {/* unit */}
      {/* <select
        name="unit"
        className="block px-2 py-1 text-xs frame-input rounded small "
        disabled={equipment.default}
        value={equipment.unit}
        onChange={(event) => {
          onEquipmentChange(index, {
            ...equipment,
            unit: event.target.value,
          });
        }}
      >
        <option value="letter">letter</option>
        <option value="number">number</option>
      </select> */}
      {/* tag form */}
      {/* <select
        name="tag_form"
        className="w-20 block px-2 py-1 text-xs frame-input rounded small"
        disabled={equipment.default}
        value={equipment.tag_form}
        onChange={(event) => {
          onEquipmentChange(index, {
            ...equipment,
            tag_form: event.target.value,
          });
        }}
      >
        <option value="shape">Shape</option>
        <option value="text">Text</option>
      </select> */}
      {/* actions */}
      <div
        className={clsx(
          'actions block h-6 w-24 pt-1',
          equipment.default ? 'hidden' : '',
        )}
      >
        <button
          type="button"
          name="Save_tag"
          className="focus:outline-none px-1 inline-block btn-zoom-big"
          onClick={() => savingEquipmentTag()}
        >
          <img src={saveIcon} alt="save" className="inline-block size-5" />
        </button>
        <button
          type="button"
          name="Delete_tag"
          className="focus:outline-none inline-block btn-zoom-big px-1"
          onClick={() => onEquipmentDelete(equipment.tag, index)}
        >
          <img src={deleteIcon} alt="save" className="inline-block size-5" />
        </button>
        {/* saving spinner */}
        {saving && <Spinner size="sm" className="inline-block ml-1 mt-0.5" />}
      </div>
    </div>
  );
};

export type EquipmentModalProps = ModalOpenProps & {
  equipments: EquipmentTag[];
  onItemAdd: () => void;
  onItemUpdate: (index: number, equipment: EquipmentTag) => void;
  onItemSave: (eqmt: EquipmentTag) => Promise<void>;
  onItemDelete: (tag: string, index?: number) => void;
  onItemCleanup: () => void;
  onSaveAll: () => void;
};

export const EquipmentSettingModal = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
  equipments,
  onItemAdd,
  onItemUpdate,
  onItemSave,
  onItemDelete,
  onItemCleanup,
  onSaveAll,
}: EquipmentModalProps) => (
  <AnimateModal
    isOpen={isOpen}
    isOnStage={isOnStage}
    lazyCloseHandler={(type) => {
      onItemCleanup();
      lazyCloseHandler(type);
      onSaveAll();
    }}
    title={EQUIPMENT_SETTINGS}
    containerClass="equipment-modal"
  >
    {/* equipment header row and new button */}
    <div className="equipment-header text-sm mt-2 py-2 flex gap-2 bottom-border-only">
      <span className="inline-block w-32">Equipment Tag</span>
      <span className="inline-block w-18">Separator</span>
      <span className="inline-block w-20">Subtype</span>
      {/* <span className="inline-block w-20">Separator</span> */}
      {/* <span className="inline-block w-20">Unit</span> */}
      {/* <span className="inline-block w-20">Tag Form</span> */}
      {/* <span className="inline-block w-16">Actions</span> */}
      <button
        type="button"
        name="Add_Row"
        className="focus:outline-none btn-zoom-big"
        onClick={() => onItemAdd()}
      >
        <PlusCircleIcon className="size-5 text-green-500" />
      </button>
    </div>
    {/* dynamic equipment rows */}
    <div className="equipment-rows-body text-xs pt-2 focus:outline-none h-64 max-h-80 overflow-scroll bottom-border-only no-scrollbar">
      {equipments.map((eqmt, index) => (
        <EquipmentRow
          key={index}
          index={index}
          equipment={eqmt}
          onEquipmentChange={onItemUpdate}
          onEquipmentDelete={onItemDelete}
          onEquipmentSave={onItemSave}
        />
      ))}
    </div>
  </AnimateModal>
);
