import { FittingMeta } from './entity';
import {
  CATALOGUE_CATEGORY as FIT,
  PAGE_UNIT,
  DimensionSuffix as SFX,
  LENGTH_TYPE as LEN,
  SymbolType,
} from './options';

/**
 * shorterfy entity type length for better display
 * @date 2025/01/21
 * @param type type name
 * @returns shorter type name
 */
export const shorterEntityType = (type: string, maxLength = 24) => {
  if (type.length < maxLength) return type;
  return type.slice(0, maxLength) + '...';
};

export const getInputSuffixBy = (
  unit: string | undefined,
  coarseGrained = false,
) => {
  if (coarseGrained) {
    return unit === PAGE_UNIT.I ? SFX.FT : SFX.M;
  }
  return unit === PAGE_UNIT.I ? SFX.I : SFX.MM;
};

export const unit2Suffix = getInputSuffixBy;

export const isFittingSymbol = (category: string) => {
  return category === FIT.D || category === FIT.P;
};

export const isLengthSymbol = (category: string) => {
  return category === LEN.P || category === LEN.D;
};

/**
 * Revised equipment checker using both `category` and `itemType`
 * @date 2024/05/08
 * @param category
 * @param itemType
 * @returns
 */
export const isEquipment = (category: string, itemType: string) => {
  return category === FIT.E || itemType === SymbolType.EQUIPMENT;
};

// ========================= OBSOLETE FUNCTIONS =================

/**
 * TODO: implement this with `zustand` store!
 * need a global catalogues cache for all, to fetch uncertain type
 * @date 2023/05/18
 * @deprecated to abandon in the coming weeks...
 */
export const ALL_DEFINED_CATALOGUES: FittingMeta[] = [];

/**
 * ***** SYSTEM LEVEL `FITTING` DEFINITIONS ******
 * **** dynamic filled by item api data *****
 * *** at `useItemCatalogue` ***
 * ******************************************
 * @deprecated to abandon in the coming weeks...
 */
export const FITTING_CATALOGUES: FittingMeta[] = [];

/**
 * ***** SYSTEM LEVEL `LENGTH` DEFINITIONS *****
 * **** dynamic filled by item api data *****
 * *** at `useItemCatalogue` ***
 * ******************************************
 * @deprecated to abandon in the coming weeks...
 */
export const LENGTH_CATALOGUES: FittingMeta[] = [];
