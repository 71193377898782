/**
 * Add New Equipment modal
 *
 * created at @2023/05/30
 */

import clsx from 'clsx';

import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { NEW_EQUIPMENT_TITLE, INPUT_MAX_18 } from '@/config';

import { useEquipmentModal } from '../hooks/useEquipmentModal';

export const AddEquipmentModal = ({
  isOpen,
  isOnStage,
  lazyCloseHandler,
}: ModalOpenProps) => {
  const MAX_INPUT_LENGTH = 18;

  const {
    eqmtName,
    setEqmtName,
    eqmtSubtype,
    setEqmtSubtype,
    sNumber,
    setSNumber,
    identifier,
    setIdentifier,
    applyNewEquipmentHandler,
    cancelNewElementHandler,
  } = useEquipmentModal(lazyCloseHandler);

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={NEW_EQUIPMENT_TITLE}
    >
      <label htmlFor="eqmtName" className="color-primary text-sm block my-4">
        EQUIPMENT NAME
        <input
          id="eqmtName"
          name="name"
          className="inline-block frame-input prefix-icon ml-3 text-sm"
          maxLength={MAX_INPUT_LENGTH}
          placeholder={INPUT_MAX_18}
          value={eqmtName}
          onChange={(event) => setEqmtName(event.target.value)}
        />
      </label>
      <label
        htmlFor="subtype"
        className="color-primary text-sm block mb-4 mt-0"
      >
        SUBTYPE(optional)
        <input
          id="subtype"
          name="subtype"
          className="inline-block frame-input prefix-icon ml-3 text-sm"
          maxLength={MAX_INPUT_LENGTH}
          placeholder={INPUT_MAX_18}
          value={eqmtSubtype}
          onChange={(event) => setEqmtSubtype(event.target.value)}
        />
      </label>
      {/* add identifier - @2024/12/10 */}
      <label
        htmlFor="subtype"
        className="color-primary text-sm block mb-4 mt-0"
      >
        IDENTIFIER(optional)
        <input
          id="serialNumber"
          name="s_number"
          className="inline-block frame-input prefix-icon ml-3 text-sm"
          maxLength={MAX_INPUT_LENGTH}
          placeholder={INPUT_MAX_18}
          value={identifier}
          onChange={(event) => setIdentifier(event.target.value)}
        />
      </label>
      {/* add serial number - @2023/09/12 */}
      <label
        htmlFor="subtype"
        className="color-primary text-sm block mb-4 mt-0"
      >
        SERIAL NUMBER(optional)
        <input
          id="serialNumber"
          name="s_number"
          className="inline-block frame-input prefix-icon ml-3 text-sm"
          maxLength={MAX_INPUT_LENGTH}
          placeholder={INPUT_MAX_18}
          value={sNumber}
          onChange={(event) => setSNumber(event.target.value)}
        />
      </label>
      {/* button row */}
      <div className="flex gap-x-10 justify-between mx-0 mt-6">
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={cancelNewElementHandler}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base bg-green shadow-md',
          )}
          onClick={applyNewEquipmentHandler}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
