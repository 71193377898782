/** feature detail info */
export const FITTINGDTL = 'detail';
export const FITTINGVER = 'version';
export const FITTINGSIZE = 'label';
export const FITTINGTEXT = 'textStyle';
/**
 * key attached to feature of symbol type, to help to decide the style for feature
 * `SymbolType`, to decide the symbol shape to draw
 */
export const FITTINGTYPE = 'type';

/**
 * basic length info from modal
 */
export const FITTINGKEY = 'currentFitting';
export const CURRENT_FEATURE = 'CURRENT_FEATURE';

export const DEFAULT_LINE_COLOR = '#CCCCCC';
export const DEFAULT_PURPLE_COLOR = 'darkviolet';
export const DEFAULT_DOT_RADIUS = 12;
/**
 * default(initial diamon radius)
 */
export const DEFAULT_DIAMOND_RADIUS = 2;
/**
 * default(initial) equiement font size
 */
export const DEFAULT_EQUIPMENT_FONT_SIZE = 6;
/**
 * initial triangle symbol radius 6, @2024/03/28
 */
export const DEFAULT_TRIANGLE_RADIUS = 4;
export const DUCK_VERSION_NUMBER = '-1';
export const LineResizingsMinLength = 20;
export const MINIMALAMPLIFYZOOMLEVEL = 5;
export const LINETHICKNESSFACTOR = 0.8;

export const zoomingFeatureMaxLengthOfMap = 1 / 3;

/**
 * limit the batch selection amount to prevent the browser crash
 * @date 2024/12/11
 */
export const BATCHSELECTIONMAXFEATRUES = 1000;

/**
 * common stroke width for all symbols
 */
export const COMMON_STROKE_WIDTH = 3;

/**
 * box stroke applying after this zoom level, initially use `1`
 * @log change value from 2 to 2.4 - 2025/03/18
 */
export const MINIMAL_LEVEL_THICKNESS_CHANGE = 2.4;

/**
 * whole map restying pending time in milisecond, NO less than `120`!
 *
 * @log change from `600` to `300` as Simon requested at 2024/04/15
 */
export const FULL_RERENDERING_DELAY = 300;

/** max zoom level for map animation target - 2025/03/25 */
export const FLY_TO_DEFAULT_ZOOM = 3;

export const fontSizeWithZoomLevel: { [level: number]: number } = {
  0: 9,
  1: 7,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 28,
  7: 36,
  8: 56,
  9: 64,
  10: 92,
};

export enum SYMBOL_PALETTE {
  /** user confirmed green */
  UCF = '#2ECC71',
  // ======================
  /** Missing any info Duct — Blue */
  MSD = '#4361EE',
  /** Missing any info Pipe — Blue */
  MSP = '#4361ee',
  // ======================
  /** Completed unconfirmed Duct — Pink */
  CUD = '#F18AB4',
  /** Completed unconfirmed Pipe — Pink */
  CUP = '#f18ab4',
  // ===========================
  /** Equipment — orange */
  EQM = '#FC913A',
  /** User added fittings — green */
  UAF = '#2eCC71',
  /** User added lines — green */
  UAL = '#2ecc71',
}
