import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { getDuctFittings, getPipeFittings, getPlumbings } from '../store';
import { FILTERS, FILTER_LABELS as LABLES, shorterEntityType } from '../types';

export const MultilevelMenu = ({
  handleFilterChange,
}: {
  handleFilterChange: (type: string) => void;
}) => (
  <ul className="nested-menu-dropdown dropdown-menu w-56">
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none "
        onClick={() => handleFilterChange(FILTERS.ALL)}
      >
        {LABLES.ALL}
      </button>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none "
        onClick={() => handleFilterChange(FILTERS.DUCTS)}
      >
        {LABLES.DUCTS}
      </button>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.PIPES)}
      >
        {LABLES.PIPES}
      </button>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none flex justify-between"
      >
        Fitting
        <ChevronRightIcon className="size-4 inline-block" />
      </button>
      {/* sub menu items */}
      <ul className="dropdown-menu dropdown-submenu w-24">
        <li className="dropdown-menu-item">
          <button
            type="button"
            className="text-left w-full focus:outline-none flex justify-between"
          >
            Duct
            <ChevronRightIcon className="size-4 inline-block" />
          </button>
          {/* === 3rd level sub menu items === */}
          <ul className="dropdown-menu dropdown-submenu triple-column w-max">
            {getDuctFittings(true).map((duct) => (
              <li
                key={duct.type}
                className="dropdown-menu-item"
                data-item={duct.type}
              >
                <button
                  type="button"
                  className="text-left w-full focus:outline-none"
                  onClick={() => handleFilterChange(duct.type)}
                >
                  <img
                    src={duct.icon}
                    alt="element-icon"
                    className="inline-block mr-4 size-5"
                  />
                  <span className="text-xs inline-block py-0">
                    {shorterEntityType(duct.label)}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </li>
        {/* Pipe group */}
        <li className="dropdown-menu-item">
          <button
            type="button"
            className="text-left w-full focus:outline-none flex justify-between"
          >
            Pipe
            <ChevronRightIcon className="size-4 inline-block" />
          </button>
          {/* === 3rd level sub menu items === */}
          <ul className="dropdown-menu dropdown-submenu triple-column w-max">
            {getPipeFittings(true).map((pipe) => (
              <li
                key={pipe.type}
                className="dropdown-menu-item"
                data-item={pipe.type}
              >
                <button
                  type="button"
                  className="text-left w-full focus:outline-none"
                  onClick={() => handleFilterChange(pipe.type)}
                >
                  <img
                    src={pipe.icon}
                    alt="element-icon"
                    className="inline-block mr-4 size-5"
                  />
                  <span className="text-xs inline-block py-0">
                    {shorterEntityType(pipe.label)}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </li>
        {/* Plumbing group - @2025/02/21 */}
        <li className="dropdown-menu-item">
          <button
            type="button"
            className="text-left w-full focus:outline-none flex justify-between"
          >
            Plumbing
            <ChevronRightIcon className="size-4 inline-block" />
          </button>
          {/* === 3rd level sub menu items === */}
          <ul className="dropdown-menu dropdown-submenu triple-column w-max">
            {getPlumbings(true).map((plum) => (
              <li
                key={plum.type}
                className="dropdown-menu-item"
                data-item={plum.type}
              >
                <button
                  type="button"
                  className="text-left w-full focus:outline-none"
                  onClick={() => handleFilterChange(plum.type)}
                >
                  <img
                    src={plum.icon}
                    alt="element-icon"
                    className="inline-block mr-4 size-5"
                  />
                  <span className="text-xs inline-block py-0">
                    {shorterEntityType(plum.label)}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.DLENT)}
      >
        {LABLES.DLENT}
      </button>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.PLENT)}
      >
        {LABLES.PLENT}
      </button>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.MXDUCT)}
      >
        {LABLES.MXDUCT}
      </button>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.MXPIPE)}
      >
        {LABLES.MXPIPE}
      </button>
    </li>
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.MXPIBO)}
      >
        {LABLES.MXPIBO}
      </button>
    </li>
    {/* select all PLUMBING symbols @2024/11/14 */}
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.PLMBS)}
      >
        {LABLES.PLMBS}
      </button>
    </li>
    {/* select all equipments @2023/05/16 */}
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.EQMT)}
      >
        {LABLES.EQMT}
      </button>
    </li>
    {/* select all offsets @2023/06/22 */}
    <li className="dropdown-menu-item">
      <button
        type="button"
        className="text-left w-full focus:outline-none"
        onClick={() => handleFilterChange(FILTERS.OFST)}
      >
        {LABLES.OFST}
      </button>
    </li>
  </ul>
);
